import type { PostHashtag } from "@js/apps/give-and-get-help/types";
import { getHashtagRawName } from "@js/components/rich-text-editor/custom-plugins/hashtag-plugin/utils/get-hashtag-raw-name";

type TextWithHashtags = {
  text: string;
  hashtags?: PostHashtag[];
};

export const splitTextByHashtags = (text: string): string[] => {
  const hashtagRegExp = /(#\w+)/gi;
  const textArray = text.split(hashtagRegExp);
  return textArray;
};

export const parseTextWithHashtags = ({ text, hashtags }: TextWithHashtags) => {
  if (!hashtags || !hashtags.length) return text;

  const uniqueHashtagsIds = new Set<number>();

  const textArray = splitTextByHashtags(text);

  const parsedTextArray = textArray.map((textFragment) => {
    const hashtagInText = hashtags.find((hashtag) => {
      return textFragment.includes(hashtag.hashtag_name);
    });

    if (!hashtagInText) {
      return textFragment;
    }

    if (uniqueHashtagsIds.has(hashtagInText.id)) {
      return textFragment;
    }

    uniqueHashtagsIds.add(hashtagInText.id);
    const hashtagRawName = getHashtagRawName(hashtagInText.hashtag_name);

    return textFragment.replace(
      `#${hashtagRawName}`,
      `<span
        class="post-hashtag-link"
        data-id="${hashtagInText.id}"
        data-hashtag_id="${hashtagInText.hashtag_id}"
        data-hashtag_name="${hashtagInText.hashtag_name}"
      >#${hashtagRawName}</span>`,
    );
  });

  return parsedTextArray.join("");
};
