import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box, Checkbox } from "@hexocean/braintrust-ui-components";
import { getError, shouldDisplayError } from "@js/forms/utils";

import { BoostFieldPanel } from "./components";
import { getFieldDescription } from "./utils";

type BoostFieldProps = {
  boost: number;
  id?: string;
  isEditPage?: boolean;
  is_boosted?: boolean;
};

export const BoostItem = ({
  boost,
  isEditPage,
  is_boosted,
}: BoostFieldProps) => {
  const title = "Boost your application.";
  const description = getFieldDescription(boost, isEditPage, is_boosted);

  return (
    <Field
      id="boost-application"
      name="is_boosted"
      title={title}
      boost={boost}
      description={description}
      isEditPage={isEditPage}
      is_boosted={is_boosted}
      component={BoostApplicationField}
    />
  );
};

type BoostRateFieldProps = {
  title: string;
  description: string;
  id: string;
  boost: number;
  isEditPage?: boolean;
  is_boosted?: boolean;
} & TypedWrappedFieldProps<boolean>;

const BoostApplicationField = ({
  title,
  description,
  boost,
  isEditPage,
  is_boosted,
  ...props
}: BoostRateFieldProps) => {
  const displayError = shouldDisplayError(props);

  return (
    <Box
      sx={{
        background:
          "linear-gradient(91.94deg, #4E86CF -2.36%, #D4E4F9 28.58%, #4E86CF 60.5%, #B0CAED 100.61%);",
        padding: "1px",
        borderRadius: "16px",
        "@media (min-width: 960px) and (max-width: 1180px)": {
          minWidth: "440px",
        },
      }}
      marginBottom={3}
    >
      <BoostFieldPanel
        title={title}
        description={description}
        displayError={displayError}
        isChecked={props.input.value}
        error={getError(props)}
      >
        {boost > 0 && (
          <Checkbox
            disabled={isEditPage && is_boosted}
            checked={props.input.value}
            onChange={(event) => props.input.onChange(event?.target?.checked)}
            className="fill-out-section-panel__checkbox"
          />
        )}
      </BoostFieldPanel>
    </Box>
  );
};
