import _ from "underscore";

import { UPDATE_INTERNAL_REVIEW } from "@js/apps/common/action-types";
import {
  ADD_NOT_INTERESTED_JOB_ID,
  CLOSE_POST_ETIQUETTE_PANEL,
  CREATE_WORK_SAMPLE_SUCCESS,
  DELETE_NOT_INTERESTED_JOB_ID,
  DELETE_WORK_SAMPLE,
  FETCH_FREELANCER_JOBS_SELECT,
  FETCH_FREELANCER_JOBS_SELECT_FAILED,
  FETCH_FREELANCER_JOBS_SELECT_SUCCESS,
  FETCH_FREELANCER_PROFILE,
  FETCH_FREELANCER_PROFILE_FAILED,
  FETCH_FREELANCER_PROFILE_SUCCESS,
  FETCH_FREELANCER_PUBLIC_PROFILE_SUCCESS,
  FETCHING_FREELANCER_ARTICLES,
  FETCHING_FREELANCER_ARTICLES_FAILED,
  FETCHING_FREELANCER_ARTICLES_SUCCESS,
  FETCHING_FREELANCER_EVENTS,
  FETCHING_FREELANCER_EVENTS_FAILED,
  FETCHING_FREELANCER_EVENTS_SUCCESS,
  FETCHING_FREELANCER_OFFERS,
  FETCHING_FREELANCER_OFFERS_FAILED,
  FETCHING_FREELANCER_OFFERS_SUCCESS,
  UPDATE_CERTIFICATES_POSITION,
  UPDATE_FREELANCER_PROFILE_SUCCESS,
  UPDATE_WORK_SAMPLE_POSITION,
  UPDATE_WORK_SAMPLE_SUCCESS,
} from "@js/apps/freelancer/action-types";
import type { FreelancerState } from "@js/types/freelancer";

const INITIAL_STATE: FreelancerState = {
  freelancerProfile: undefined,
  fetchingFreelancerProfile: false,

  workSampleItems: [],
  fetchingWorkSample: false,
  editedWorkSampleId: undefined,

  publicProfile: undefined,

  fetchingJobList: false,
  jobList: [],

  fetchingJobListSelect: false,
  jobSelectList: [],

  notInterestedJobIds: [],

  fetchingFreelancerOffers: false,
  offers: null,

  fetchingEvents: false,
  events: {
    results: [],
    count: 0,
  },

  fetchingArticles: false,
  articles: [],
};

export default (state = INITIAL_STATE, action): FreelancerState => {
  switch (action.type) {
    case FETCH_FREELANCER_PROFILE:
      return {
        ...state,
        workSampleItems: [],
        fetchingFreelancerProfile: true,
      };

    case FETCH_FREELANCER_PROFILE_SUCCESS:
      // For now, we only support one freelancer profile.
      return {
        ...state,
        freelancerProfile: action.payload,
        workSampleItems: action.payload?.portfolio_items || [],
        fetchingFreelancerProfile: false,
      };

    case FETCH_FREELANCER_PROFILE_FAILED:
      return {
        ...state,
        fetchingFreelancerProfile: false,
      };

    case UPDATE_FREELANCER_PROFILE_SUCCESS:
      return {
        ...state,
        freelancerProfile: { ...state.freelancerProfile, ...action.payload },
      };

    case UPDATE_CERTIFICATES_POSITION:
      return {
        ...state,
        publicProfile: state.publicProfile
          ? {
              ...state.publicProfile,
              freelancer_certificates: action.payload,
            }
          : undefined,
      };

    case UPDATE_WORK_SAMPLE_POSITION:
      return {
        ...state,
        fetchingWorkSample: false,
        workSampleItems: action.payload,
      };

    case DELETE_WORK_SAMPLE:
      return {
        ...state,
        workSampleItems: _.reject(
          state.workSampleItems,
          (i) => i.id === action.payload,
        ),
      };

    case CREATE_WORK_SAMPLE_SUCCESS:
      return {
        ...state,
        workSampleItems: [...state.workSampleItems, action.payload],
      };

    case UPDATE_WORK_SAMPLE_SUCCESS: {
      const newWorkSample = [...state.workSampleItems];
      const oldIndex = _.findIndex(
        newWorkSample,
        (i) => i.id === action.payload.id,
      );

      newWorkSample[oldIndex] = action.payload;

      return { ...state, workSampleItems: newWorkSample };
    }

    case FETCH_FREELANCER_PUBLIC_PROFILE_SUCCESS:
      return {
        ...state,
        publicProfile: action.payload,
        workSampleItems: action.payload?.portfolio_items || [],
      };

    case UPDATE_INTERNAL_REVIEW: {
      const { average_internal_review_rating, internal_review_ratings_count } =
        action.payload;

      return {
        ...state,
        publicProfile: state.publicProfile
          ? {
              ...state.publicProfile,
              average_internal_review_rating,
              internal_review_ratings_count,
            }
          : undefined,
      };
    }

    case FETCH_FREELANCER_JOBS_SELECT:
      return {
        ...state,
        fetchingJobListSelect: true,
        jobSelectList: [],
      };
    case FETCH_FREELANCER_JOBS_SELECT_SUCCESS:
      return {
        ...state,
        fetchingJobListSelect: false,
        jobSelectList: action.payload,
      };
    case FETCH_FREELANCER_JOBS_SELECT_FAILED:
      return {
        ...state,
        fetchingJobListSelect: false,
        jobSelectList: [],
      };

    case ADD_NOT_INTERESTED_JOB_ID:
      return {
        ...state,
        notInterestedJobIds: [...state.notInterestedJobIds, action.payload],
      };

    case DELETE_NOT_INTERESTED_JOB_ID:
      return {
        ...state,
        notInterestedJobIds: state.notInterestedJobIds.filter(
          (id) => id !== action.payload,
        ),
      };

    case FETCHING_FREELANCER_OFFERS:
      return {
        ...state,
        fetchingFreelancerOffers: true,
        offers: null,
      };
    case FETCHING_FREELANCER_OFFERS_SUCCESS:
      return {
        ...state,
        fetchingFreelancerOffers: false,
        offers: action.payload,
      };
    case FETCHING_FREELANCER_OFFERS_FAILED:
      return {
        ...state,
        fetchingFreelancerOffers: false,
        offers: null,
      };
    case FETCHING_FREELANCER_EVENTS:
      return {
        ...state,
        fetchingEvents: true,
      };
    case FETCHING_FREELANCER_EVENTS_SUCCESS:
      return {
        ...state,
        fetchingEvents: false,
        events: action.payload,
      };
    case FETCHING_FREELANCER_EVENTS_FAILED:
      return {
        ...state,
        fetchingEvents: false,
        events: null,
      };

    case FETCHING_FREELANCER_ARTICLES:
      return {
        ...state,
        fetchingArticles: true,
      };
    case FETCHING_FREELANCER_ARTICLES_SUCCESS:
      return {
        ...state,
        fetchingArticles: false,
        articles: action.payload,
      };
    case FETCHING_FREELANCER_ARTICLES_FAILED:
      return {
        ...state,
        fetchingArticles: false,
        articles: null,
      };
    case CLOSE_POST_ETIQUETTE_PANEL:
      if (state.freelancerProfile) {
        const updatedUser = {
          ...state.freelancerProfile.user,
          has_started_creating_post: true,
        };

        return {
          ...state,
          freelancerProfile: {
            ...state.freelancerProfile,
            user: updatedUser,
          },
        };
      }
      return state;

    default:
      return state;
  }
};
