import { useEffect } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

import { Box } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { fetchFreelancerPublicProfile } from "@js/apps/freelancer/actions";
import { SpaceTabs } from "@js/apps/spaces/components/space-tabs";
import { PublicSpacePage } from "@js/apps/spaces/views/public-space-page";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { AppLayout } from "@js/layouts/app";
import { castToInteger } from "@js/utils/numbers";

import { useGetSpaceDetailsQuery } from "../../api";
import { SpaceHeader } from "../../components";
import { JoinThisSpaceModalInstance } from "../../components/join-the-space-modal/join-this-space-modal-instance";
import { useHandleGetSpaceError } from "../../hooks/use-handle-get-space-error";
import { useIsSpaceAdmin } from "../../hooks/use-is-space-admin";
import { useIsSpaceMember } from "../../hooks/use-is-space-member";
import { useSpaceNotifications } from "../../hooks/use-space-notifications";

export const SpacePageWrapper = () => {
  const params = useParams();
  const spaceId = castToInteger(params.spaceId);
  const user = useUser();

  if (!spaceId) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  const showPublicSpacePage = !user;

  if (showPublicSpacePage) {
    return <PublicSpacePage id={spaceId} />;
  }

  return <SpacePage id={spaceId} />;
};

type SpacePageProps = {
  id: number;
};

export const SpacePage = ({ id }: SpacePageProps) => {
  const dispatch = useAppDispatch();
  const user = useUser();

  const { data: spaceData, error } = useGetSpaceDetailsQuery(
    { id },
    { refetchOnMountOrArgChange: true },
  );

  useHandleGetSpaceError(error);

  const { isSpaceMember, isFetching: isSpaceMemberFetching } = useIsSpaceMember(
    { spaceId: id },
  );
  const { isSpaceAdmin } = useIsSpaceAdmin({ spaceId: id });
  const { areNotificationsTurnOn } = useSpaceNotifications();
  const userSpaces = useAppSelector(
    (state) => state.freelancer.publicProfile?.spaces,
  );

  useEffect(() => {
    if (user && user?.freelancer && !userSpaces) {
      dispatch(fetchFreelancerPublicProfile(user?.freelancer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppLayout
      bgcolor="var(--soft-green)"
      pageTitle={spaceData?.name ?? "Spaces"}
      setBgOnBody
    >
      {!!spaceData && (
        <Box
          display="flex"
          flexDirection="column"
          justifyItems="center"
          mx="auto"
          width="100%"
        >
          <SpaceHeader
            id={spaceData.id}
            title={spaceData.name}
            avatarSource={spaceData.avatar_thumbnail ?? ""}
            membersCount={spaceData.total_members}
            isMember={isSpaceMember}
            isAdmin={isSpaceAdmin}
            areNotificationsTurnOn={areNotificationsTurnOn}
            isSpaceMemberFetching={isSpaceMemberFetching}
          />

          <SpaceTabs spaceId={spaceData.id} isSpaceMember={isSpaceMember} />
          <Outlet />
        </Box>
      )}

      <JoinThisSpaceModalInstance />
    </AppLayout>
  );
};
