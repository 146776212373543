import React, { useCallback } from "react";
import { SubmissionError } from "redux-form";

import { useUser } from "@js/apps/common/hooks/use-user";
import { fetchEmployerPublicProfile } from "@js/apps/employer/actions";
import { fetchFreelancerPublicProfile } from "@js/apps/freelancer/actions";
import {
  useCreateJobCoworkerReviewMutation,
  useCreateJobEmploymentReviewMutation,
  useCreateJobWorkReviewMutation,
  useGetOpenReviewProcessesQuery,
} from "@js/apps/reviews/api";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import { isErrorWithMessage } from "@js/types/errors";
import type { JobReview } from "@js/types/reviews";
import { assertUnreachable, formatErrorForSnackbar } from "@js/utils";

import { REVIEW_SUBJECT } from "../constants";

import { WriteReviewForm } from "./form";

type WriteReviewProps = {
  reviewSubjectId: number;
  reviewSubject: EnumType<typeof ENUMS.AccountType>;
  onClose: () => void;
  onSuccess: () => void;
};

export const WriteReview = ({
  reviewSubject,
  reviewSubjectId,
  onClose,
  onSuccess,
}: WriteReviewProps) => {
  const dispatch = useAppDispatch();

  const user = useUser();

  const [saveEmploymentReview] = useCreateJobEmploymentReviewMutation();
  const [saveCoworkerReview] = useCreateJobCoworkerReviewMutation();
  const [saveWorkReview] = useCreateJobWorkReviewMutation();

  const getContentType = useCallback(() => {
    return reviewSubject === REVIEW_SUBJECT.EMPLOYER
      ? ENUMS.ReviewType.EMPLOYMENT_REVIEW
      : user?.account_type === ENUMS.AccountType.EMPLOYER
        ? ENUMS.ReviewType.WORK_REVIEW
        : ENUMS.ReviewType.COWORKER_REVIEW;
  }, [reviewSubject, user?.account_type]);

  const {
    data: openReviewProcessesList,
    isLoading: isLoadingOpenReviewProcessesList,
  } = useGetOpenReviewProcessesQuery({
    content_type: getContentType(),
    subject: reviewSubjectId,
  });

  const saveReview = (values: JobReview) => {
    const contentType = getContentType();

    switch (contentType) {
      case ENUMS.ReviewType.WORK_REVIEW:
        return saveWorkReview(values);
      case ENUMS.ReviewType.COWORKER_REVIEW:
        return saveCoworkerReview(values);
      case ENUMS.ReviewType.EMPLOYMENT_REVIEW:
        return saveEmploymentReview(values);
      default:
        assertUnreachable(contentType);
        Snackbar.error("Something went wrong. Please try again later.");
        return null;
    }
  };

  const onSubmit = async (values) => {
    const saveReviewResult = saveReview?.(values);

    if (saveReviewResult) {
      return saveReviewResult
        .unwrap()
        .then(() => onClose())
        .then(() => onSuccess())
        .then(() => fetchPublicProfile())
        .catch((error) => {
          if (isErrorWithMessage(error?.data)) {
            const errors = error?.data._error;
            Snackbar.error(formatErrorForSnackbar(errors));
          }
          if (error?.data) throw new SubmissionError(error.data);
        });
    }
  };

  const fetchPublicProfile = () => {
    if (reviewSubject === REVIEW_SUBJECT.EMPLOYER) {
      return dispatch(fetchEmployerPublicProfile(reviewSubjectId));
    }

    return dispatch(fetchFreelancerPublicProfile(reviewSubjectId));
  };

  if (isLoadingOpenReviewProcessesList) {
    return null;
  }

  if (!!openReviewProcessesList?.length) {
    return (
      <WriteReviewForm
        openReviewProcessesList={openReviewProcessesList}
        initialValues={{
          review_process: openReviewProcessesList[0]?.id,
          subject: reviewSubjectId,
          reviewer: user?.freelancer ? user?.freelancer : user?.employer,
        }}
        reviewSubject={reviewSubject}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    );
  } else {
    return null;
  }
};
