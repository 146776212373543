import { Box } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks/use-user";
import { MyWorkBaseCard } from "@js/apps/freelancer/components";
import {
  BasicJobInfo,
  PaymentAmount,
  ViewJobButton,
} from "@js/apps/freelancer/components/my-work-base-component/components";
import { ApplicationBoostFeatureFlagWrapper } from "@js/apps/jobs/components/application-boost-feature-flag-wrapper";
import { BoostJobButton } from "@js/apps/jobs/components/boost-job-button";
import { Date } from "@js/components/date";
import type { FreelancerBid } from "@js/types/jobs";
import { DateFormats } from "@js/utils/date/types";

import { ActionMenu } from "./action-menu";
type BidListItemProps = {
  bid: FreelancerBid;
};
export const BidListItem = ({ bid }: BidListItemProps) => {
  const user = useUser();
  return (
    <MyWorkBaseCard
      job={bid.job}
      bottomSection={<BasicJobInfo job={bid.job} />}
      rightSection={
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          minWidth="210px"
          mt={0.5}
        >
          Applied{" "}
          <Date date={bid.created} format={DateFormats["January 1, 1970"]} />
          <Box display="flex" alignItems="center" gap={1.5}>
            <Box m="8px 0px">
              <PaymentAmount
                paymentAmount={bid.payment_amount}
                paymentType={bid.payment_type}
              />
            </Box>
            <ActionMenu bid={bid} mr={-2.25} />
          </Box>
          <Box display={"flex"} gap={1}>
            <ApplicationBoostFeatureFlagWrapper>
              <BoostJobButton
                variant="secondary"
                color="secondary"
                fullWidth={false}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  flexShrink: 0,
                  height: 40,
                }}
                alreadyBoosted={bid?.is_boosted}
                bidId={bid.id}
                isOpen={bid?.job?.is_open}
                boostCredit={user?.boost_credit}
              />
            </ApplicationBoostFeatureFlagWrapper>
            <ViewJobButton jobId={bid.job.id} />
          </Box>
        </Box>
      }
    />
  );
};
