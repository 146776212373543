import type { FC } from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightIcon,
  CloseIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { openBoostModal } from "@js/apps/jobs/components/job-list-boost-modal/job-list-boost-modal";

type BoostBannerProps = {
  onDismiss: () => void;
};

export const BoostBanner: FC<BoostBannerProps> = ({ onDismiss }) => {
  const openModal = async () => {
    openBoostModal();
  };
  const isTablet = useMediaQuery("md");

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      pr={isTablet ? 0 : 2}
      pl={2}
      py={1}
      position="relative"
      bgcolor="var(--info-2)"
    >
      <Box display="flex" flexWrap="wrap" gap={1} mx="2">
        <Box
          display="flex"
          justifyContent="center"
          px={1.5}
          py={0.25}
          bgcolor="var(--medium-blue)"
          borderRadius={isTablet ? "12px" : "35px"}
          border="2px solid var(--white)"
        >
          <Typography
            component="p"
            size="small"
            sx={{ color: "var(--info-2)" }}
            fontWeight={500}
          >
            👀{"  "}Stand out with Application Boost: Elevate your applications
            to the top and get noticed by employers.
          </Typography>
        </Box>

        <Button
          onClick={openModal}
          variant="no-padding"
          size="x-small"
          fontWeight={500}
          endIcon={<ArrowRightIcon />}
          sx={{
            color: "var(--white)",
            margin: isTablet ? "auto" : "unset",
            "& > .icon--end": {
              marginLeft: "2px !important",
              marginRight: "0 !important",
            },
          }}
        >
          Get Application Boosts
        </Button>
      </Box>

      <IconButton
        size="x-small"
        variant="transparent"
        aria-label="Dismiss boost banner"
        sx={
          isTablet
            ? { alignSelf: "baseline" }
            : { position: "absolute", right: 1 }
        }
        onClick={onDismiss}
      >
        <CloseIcon sx={{ color: "var(--white)" }} />
      </IconButton>
    </Box>
  );
};
