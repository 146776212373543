import React, { useMemo, useState } from "react";
import {
  Field,
  getFormSyncErrors,
  hasSubmitFailed,
  isSubmitting,
  setSubmitFailed,
  setSubmitSucceeded,
} from "redux-form";

import {
  Box,
  Button,
  FormHelperText,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { nextStepClicked } from "@js/apps/give-and-get-help/actions";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import type { CreateOrEditPostMode } from "@js/apps/give-and-get-help/types";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { useResourcesContext } from "../../context";
import type {
  CREATE_POST_FORM_ID,
  EDIT_POST_FORM_ID,
} from "../../form/constants";
import { useCheckForSelfPromotion } from "../../hooks/use-check-for-self-promotion";
import { CreateOrEditPostErrorSection } from "../create-or-edit-post-error-section";
import { CreateOrEditPostWarningSection } from "../create-or-edit-post-warning-section";
import type { PostFooterActions } from "../footer-action-buttons-section";
import { CreateOrEditPostFooterActionsButtonsSection } from "../footer-action-buttons-section";

const FooterWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Box className="footer-wrapper">{children}</Box>;
};

export type CreateOrEditPostStepOneFooterProps = {
  mode: CreateOrEditPostMode;
  onNextClick: () => void;
  formId: typeof CREATE_POST_FORM_ID | typeof EDIT_POST_FORM_ID;
  onFirstStepFail: (errors: string[]) => void;
  isLinkFieldOpen: boolean;
  disablePoll: boolean | undefined;
};

export const CreateOrEditPostStepOneFooter = ({
  onNextClick,
  formId,
  onFirstStepFail,
  mode,
  isLinkFieldOpen,
  disablePoll,
}: CreateOrEditPostStepOneFooterProps) => {
  const [showErrorSection, setShowErrorSection] = useState(false);
  const syncErrorsObj = useAppSelector((state) =>
    getFormSyncErrors(formId)(state),
  );
  const dispatch = useAppDispatch();
  const location = usePostLocationContext();
  const {
    checkPostForSelfPromotion,
    isCheckInProgress,
    clearWarning,
    ignoreWarning,
    isWarningIgnored,
  } = useCheckForSelfPromotion(formId);

  const handleOnNextClick = async () => {
    dispatch(
      nextStepClicked({
        mode,
        composer_location: location,
      }),
    );

    const fieldsWithError = Object.keys(syncErrorsObj);

    if (fieldsWithError.length !== 0) {
      dispatch(setSubmitFailed(formId)); // we want to show result of the validation
      onFirstStepFail(fieldsWithError);
      setShowErrorSection(true);
      return;
    }

    const selfPromotion = !isWarningIgnored
      ? await checkPostForSelfPromotion()
      : false;

    if (selfPromotion) {
      dispatch(setSubmitFailed(formId)); // we want to show result of the validation
      onFirstStepFail(fieldsWithError);
      setShowErrorSection(true);
      ignoreWarning();
      return;
    }

    dispatch(setSubmitSucceeded(formId)); // reset to previous state
    return onNextClick();
  };

  const hasMissingTitleOrText =
    "text" in syncErrorsObj || "title" in syncErrorsObj;
  const hasPendingUploads = "attachments" in syncErrorsObj;
  const errorMessage = useMemo(() => {
    if (hasMissingTitleOrText) {
      return "Please complete your post before proceeding";
    }

    if (hasPendingUploads) {
      return "Please wait, still uploading...";
    }

    return;
  }, [hasPendingUploads, hasMissingTitleOrText]);

  const buttonsToRender = useMemo(() => {
    const buttonsKeysToRender = [
      POST_FOOTER_BUTTONS_KEYS.POST_FILE,
      POST_FOOTER_BUTTONS_KEYS.POST_IMAGE,
      POST_FOOTER_BUTTONS_KEYS.POST_LINK,
      !disablePoll ? POST_FOOTER_BUTTONS_KEYS.POST_POLL : null,
    ].filter((buttonKey): buttonKey is PostFooterActions => Boolean(buttonKey));
    return buttonsKeysToRender.map((name) => ({
      name,
    }));
  }, [disablePoll]);

  const isEditingPost = mode === "edit-post";
  const isEditingALink = isEditingPost && isLinkFieldOpen;
  const { isLinkMetaLoading } = useResourcesContext();

  const shouldNextButtonBeDisabled =
    isLinkMetaLoading || isEditingALink || isCheckInProgress;

  return (
    <>
      {showErrorSection && (
        <Field name="_error_section" component={CreateOrEditPostErrorSection} />
      )}
      <Field
        name="_warning_section"
        component={CreateOrEditPostWarningSection}
        onCloseWarning={clearWarning}
      />
      <FooterWrapper>
        <Box display="flex" alignItems="center">
          <CreateOrEditPostFooterActionsButtonsSection
            mode={mode}
            buttons={buttonsToRender}
          />
        </Box>
        {showErrorSection && errorMessage && (
          <Typography
            error
            component="p"
            variant="paragraph"
            size="small"
            className="footer-wrapper__error-message"
          >
            {errorMessage}
          </Typography>
        )}
        <Button
          variant="positive-2"
          shape="squared"
          size="medium"
          onClick={handleOnNextClick}
          disabled={shouldNextButtonBeDisabled}
        >
          Next
          {isCheckInProgress && (
            <Loader
              className="footer-wrapper__loader"
              size={20}
              color="inherit"
            />
          )}
        </Button>
      </FooterWrapper>
    </>
  );
};

const POST_FOOTER_BUTTONS_KEYS: Record<string, PostFooterActions> = {
  POST_FILE: "post-file",
  POST_IMAGE: "post-image",
  POST_LINK: "post-link",
  POST_POLL: "post-poll",
};

type CreateOrEditPostStepTwoFooterProps = {
  onPreviousClick: () => void;
  isEditMode?: boolean;
  formId: typeof CREATE_POST_FORM_ID | typeof EDIT_POST_FORM_ID;
};

export const CreateOrEditPostStepTwoFooter = ({
  onPreviousClick,
  isEditMode,
  formId,
}: CreateOrEditPostStepTwoFooterProps) => {
  const { clearWarning } = useCheckForSelfPromotion(formId);

  const isSubmitFailed = useAppSelector((state) =>
    hasSubmitFailed(formId)(state),
  );

  const isSubmittingPost = useAppSelector((state) =>
    isSubmitting(formId)(state),
  );

  const syncErrorsObj = useAppSelector((state) =>
    getFormSyncErrors(formId)(state),
  );

  const hasMissingCategory = "category" in syncErrorsObj;

  return (
    <>
      {isSubmitFailed && (
        <Field name="" component={CreateOrEditPostErrorSection} isSecondStep />
      )}
      <Field
        name="_warning_section"
        component={CreateOrEditPostWarningSection}
        onCloseWarning={clearWarning}
      />
      <FooterWrapper>
        <Button
          variant="secondary"
          shape="squared"
          size="medium"
          onClick={onPreviousClick}
        >
          Back
        </Button>

        {isSubmitFailed && hasMissingCategory && (
          <FormHelperText
            className="footer-wrapper__error-message footer-wrapper__error-message--step-two"
            error
          >
            <Typography component="p" variant="paragraph" size="small">
              Please complete your post before proceeding
            </Typography>
          </FormHelperText>
        )}

        <Button
          type="submit"
          variant="positive-2"
          shape="squared"
          size="medium"
        >
          {isEditMode ? "Update post" : "Create post"}
          {isSubmittingPost && (
            <Loader
              className="footer-wrapper__loader"
              size={20}
              color="inherit"
            />
          )}
        </Button>
      </FooterWrapper>
    </>
  );
};
