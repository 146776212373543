import { useMemo } from "react";
import { Field, Fields } from "redux-form";
import _ from "underscore";

import { Box } from "@hexocean/braintrust-ui-components";
import { CodeIcon } from "@hexocean/braintrust-ui-components/Icons";
import { createFiltersFormAndHook } from "@js/apps/filters";
import { BidsTalentLocationFilter } from "@js/apps/jobs/apps/bids/forms/bid-location-filter";
import { ReactionsFeedbackFilters } from "@js/apps/jobs/components/filtering-actions/reactions-feedback-filters";
import { JOB_EVENTS_PAGE_QUERY_PARAM } from "@js/apps/jobs/components/job-events-button/const";
import { SelectField } from "@js/forms/fields/select/select";
import { useAppSelector } from "@js/hooks/redux";

import { getSelectedBidsTotal } from "../../apps/bids/components/bulk-select-actions/bid-bulk-selection-slice";
import { BidSelectionCheckbox } from "../../apps/bids/components/bulk-select-actions/bid-selection-checkbox";

import type {
  FORMS_FREELANCER_BID_FILTER_OPTION_ALL,
  FreelancerBidFilterOptionType,
} from "./get-options";
import { getOptions } from "./get-options";

import styles from "./style.module.scss";

type FilteringActionsProps = {
  isMatcher: boolean;
  jobType: EnumType<typeof ENUMS.JobType>;
  jobId: number;
};

type ReactionType = EnumType<typeof ENUMS.BidReactionType>;

const FORMS_BID_STATUS_FILTER_OPTIONS = [
  { value: "", label: "All stages" },
  {
    value: ENUMS.BidStatus.NEW,
    label: ENUMS.BidStatusLabels[ENUMS.BidStatus.NEW],
  },
  {
    value: ENUMS.BidStatus.VIEWED,
    label: ENUMS.BidStatusLabels[ENUMS.BidStatus.VIEWED],
  },
  {
    value: ENUMS.BidStatus.INTERVIEWING,
    label: ENUMS.BidStatusLabels[ENUMS.BidStatus.INTERVIEWING],
  },
  {
    value: ENUMS.BidStatus.OFFER_MADE,
    label: ENUMS.BidStatusLabels[ENUMS.BidStatus.OFFER_MADE],
  },
] as const;

const SORTING_OPTIONS = [
  { label: "Sorted by match", value: "-match_score" },
  { label: "Sorted by date", value: "-created" },
] as const;

type Filters = {
  top_match?: boolean;
  ordering?: (typeof SORTING_OPTIONS)[number]["value"];
  reaction?: ReactionType;
  is_hidden?: boolean;
};

type BidsFormValues = Filters & {
  status?: (typeof FORMS_BID_STATUS_FILTER_OPTIONS)[number]["value"];
  ids?: Array<number>;
  talent_status: (typeof FORMS_FREELANCER_BID_FILTER_OPTION_ALL)[number]["value"];
};

export type BidsParamsValues = Filters & {
  status?: Array<EnumType<typeof ENUMS.BidStatus> | "">;
  ids?: Array<number>;
  talent_status: EnumType<typeof ENUMS.FreelancerBidFilterStatus>;
};

const MAPPED_OFFER_MADE_OPTION = [
  ENUMS.BidStatus.OFFER_MADE,
  ENUMS.BidStatus.OFFER_DECLINED,
  ENUMS.BidStatus.OFFER_CANCELED,
  ENUMS.BidStatus.HIRED,
];

export const [BidListFiltersForm, useBidListFilters] = createFiltersFormAndHook<
  BidsFormValues,
  BidsParamsValues
>(
  {
    submitOnChange: true,
    useAllURLParams: true,
    paramsToIgnore: ["tab", "back", JOB_EVENTS_PAGE_QUERY_PARAM],
    defaultFormValues: {
      talent_status: ENUMS.FreelancerBidFilterStatus.ALL,
      status: "",
      ordering: SORTING_OPTIONS[0].value,
      reaction: undefined,
      top_match: undefined,
      is_hidden: false,
    },
    defaultParamsValues: {
      talent_status: ENUMS.FreelancerBidFilterStatus.ALL,
      status: [],
      ordering: SORTING_OPTIONS[0].value,
      reaction: undefined,
      is_hidden: undefined,
      ids: undefined,
    },
    mapValuesToParams: (values) => {
      if (values.status === ENUMS.BidStatus.OFFER_MADE) {
        const newValues = {
          ...values,
          status: MAPPED_OFFER_MADE_OPTION,
        };

        return newValues;
      }

      const newValues = {
        ...values,
        status: values.status ? [values.status] : [],
      };

      return newValues;
    },
    mapParamsToValues: (values) => {
      if (_.isEqual(values.status, MAPPED_OFFER_MADE_OPTION)) {
        const newValues = {
          ...values,
          status: ENUMS.BidStatus.OFFER_MADE,
        };
        return newValues;
      }

      const newValues = {
        ...values,
        status: values.status
          ? (values.status[0] as BidsFormValues["status"])
          : undefined,
      };

      return newValues;
    },
    mapParamsToFetchValues: (params) => {
      if (typeof params.ids === "string") {
        params.ids = params.ids
          .split(",")
          .map((item) => parseInt(item.trim(), 10));
      }

      if (typeof params.ids === "number") {
        params.ids = [params.ids];
      }
    },
  },
  { className: styles.bidFiltersForm },
);

export const FilteringActions = ({
  isMatcher,
  jobType,
  jobId,
}: FilteringActionsProps) => {
  const selectedBidsTotal = useAppSelector(getSelectedBidsTotal);

  const shouldRender = selectedBidsTotal === 0;

  const options: FreelancerBidFilterOptionType = useMemo(
    () => getOptions(isMatcher, jobType),
    [isMatcher, jobType],
  );

  if (!shouldRender) {
    return null;
  }

  return (
    <Box className={styles.bidFiltersActions}>
      <BidSelectionCheckbox />
      <BidListFiltersForm form="bid-filters" destroyOnUnmount={false}>
        <div className={styles.bidFiltersContent}>
          <Field
            id="talent_status"
            name="talent_status"
            component={SelectField}
            options={options}
            sx={{ py: "10px" }}
            displayEmpty={() => true}
            style={{
              borderRadius: "8px",
              height: "48px",
            }}
            noLabel
          />
          <Field
            name="status"
            id="bid-status"
            component={SelectField}
            options={FORMS_BID_STATUS_FILTER_OPTIONS}
            sx={{ py: "10px" }}
            displayEmpty={() => true}
            style={{
              borderRadius: "8px",
              height: "48px",
            }}
            noLabel
          />

          <BidsTalentLocationFilter jobId={jobId} />

          <Fields
            names={["reaction", "is_hidden"]}
            component={ReactionsFeedbackFilters}
          />

          <Field
            name="ordering"
            className={styles.bidFiltersSortBtn}
            component={SelectField}
            options={SORTING_OPTIONS}
            noLabel
            IconComponent={CodeIcon}
            sx={{
              "& .MuiSvgIcon-root": {
                right: "unset",
                left: "7px",
                transform: "rotate(90deg)",
                color: "var(--black)",
              },
              "& .MuiInputBase-input": {
                paddingLeft: "40px!important",
                paddingRight: "16px!important",
              },
            }}
            style={{
              borderRadius: "8px",
              height: "48px",
            }}
          />
        </div>
      </BidListFiltersForm>
    </Box>
  );
};
