import type { ReactNode } from "react";

import { InputAdornment } from "@hexocean/braintrust-ui-components";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";

export const BudgetOptionLabel = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <BraintrustIcon sx={{ fontSize: "inherit", mr: 0.5 }} /> {children} BTRST
    </>
  );
};

export type CustomBudgetRateEndAdornmentProps = {
  focus: boolean;
  disabled?: boolean;
};

export const CustomBudgetRateEndAdornment = ({
  focus,
  disabled,
}: CustomBudgetRateEndAdornmentProps): JSX.Element => (
  <InputAdornment
    disableTypography
    position="end"
    sx={{
      color: focus || disabled ? "var(--grey-2)" : "inherit",
      cursor: "pointer",
      ml: "4px",
      fontWeight: 500,
    }}
  >
    BTRST
  </InputAdornment>
);

export const CustomBudgetRateStartAdornment = () => (
  <InputAdornment sx={{ color: "inherit", mr: 0.5 }} position="start">
    <BraintrustIcon sx={{ fontSize: "inherit" }} />
  </InputAdornment>
);
