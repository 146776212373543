import { useEffect } from "react";
import { Field, FieldArray } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { hideLeftSidebar, showLeftSidebar } from "@js/apps/common/actions";
import { FormLayoutWrapper } from "@js/apps/common/components";
import { useUser } from "@js/apps/common/hooks/use-user";
import {
  AvailabilityFieldContainer,
  BidRateSection,
  CalendarField,
  FieldSectionPanelWithImportance,
  NewApplicationAnswersField,
} from "@js/apps/jobs/forms/fields";
import { AddResumeToBidField } from "@js/apps/jobs/forms/fields/resume/add-resume-to-bid";
import { useAppDispatch } from "@js/hooks";
import type { OverwriteContentComponentProps } from "@js/layouts/app";
import { AppLayout } from "@js/layouts/app";
import type { BidAttachment, Job } from "@js/types/jobs";

import {
  BidErrorSection,
  JobBidHeaderPortal,
} from "../../apps/bids/components";
import { useCreateBid, useEditBid } from "../../apps/bids/hooks";
import {
  BudgetDetail,
  DeadlineDetail,
  LocationDetail,
  LocationRequirementWarning,
  TimezoneDetail,
  WorkTimeDetail,
} from "../../components";
import { ApplicationBoostFeatureFlagWrapper } from "../../components/application-boost-feature-flag-wrapper";
import { BoostItem } from "../../forms/fields/boosts";
import { JobNewBidFileDropField } from "../../forms/fields/job-new-bid-file-drop-field";
import { LocationAndTimezoneRequirements } from "../../forms/fields/location-timezone-requirements";

export const JobNewBidPage = () => {
  return (
    <AppLayout
      pageTitle="Apply to job"
      bgcolor="var(--off-white)"
      className="overflow-initial"
      disableSubheaderPortal
      hideMenu
      OverwriteContentComponent={JobNewBidPageContent}
      children={null}
    />
  );
};

const JobNewBidPageContent = (props: OverwriteContentComponentProps) => {
  const { job, loading, ...formProps } = useCreateBid();

  return (
    <FormLayoutWrapper
      formId="job-new-bid-form"
      formProps={formProps}
      loading={loading || !job}
      aboveContentFullWidthSocket={<DetailsSubheader job={job} />}
      {...props}
    >
      {job && (
        <>
          <JobBidHeaderPortal job={job} isEditPage={false} />
          <Box mb="32px">
            <LocationRequirementWarning job={job} />
          </Box>
        </>
      )}
      <JobBidFormFields job={job} isEditPage={false} />
    </FormLayoutWrapper>
  );
};

export const JobEditBidPage = () => {
  return (
    <AppLayout
      pageTitle="Edit proposal"
      bgcolor="var(--off-white)"
      disableSubheaderPortal
      hideMenu
      OverwriteContentComponent={JobEditBidPageContent}
      children={null}
    />
  );
};

const JobEditBidPageContent = (props: OverwriteContentComponentProps) => {
  const { job, bid, loading, attachments, ...formProps } = useEditBid();

  return (
    <FormLayoutWrapper
      formId="job-edit-bid-form"
      formProps={formProps}
      loading={loading || !job}
      aboveContentFullWidthSocket={<DetailsSubheader job={job} />}
      {...props}
    >
      {job && <JobBidHeaderPortal job={job} isEditPage={true} />}
      <JobBidFormFields
        job={job}
        is_boosted={bid?.is_boosted}
        existing={attachments}
        isEditPage={true}
      />
    </FormLayoutWrapper>
  );
};

type JobBidFormProps = {
  job: Job | null | undefined;
  isEditPage: boolean;
  existing?: Array<BidAttachment> | undefined;
  is_boosted?: boolean;
};

const JobBidFormFields = ({
  job,
  is_boosted,
  existing,
  isEditPage,
}: JobBidFormProps) => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const boost_credit = user?.boost_credit ? user?.boost_credit : 0;

  useEffect(() => {
    dispatch(hideLeftSidebar());
    return () => {
      dispatch(showLeftSidebar());
    };
  }, [dispatch]);

  if (!job) {
    return null;
  }

  return (
    <>
      <Typography component="h1" variant="title" fontWeight={400} mb={4}>
        Fill out your application
      </Typography>
      <div className="job-bid-error-section">
        <Field
          name="_error_section_field"
          job={job}
          component={BidErrorSection}
        />
      </div>
      <div className="job-bid-form">
        <div>
          <ApplicationBoostFeatureFlagWrapper>
            <BoostItem
              boost={boost_credit}
              is_boosted={is_boosted}
              isEditPage={isEditPage}
            />
          </ApplicationBoostFeatureFlagWrapper>

          <BidRateSection job={job} />
          <LocationAndTimezoneRequirements job={job} />
          <AvailabilityFieldContainer />
          <Field name="calendar_url" component={CalendarField} />
          <Field
            name="resume"
            component={AddResumeToBidField}
            isRequired={job?.is_resume_required}
            isEditPage={isEditPage}
          />
          <FieldSectionPanelWithImportance
            id="attachement"
            title="Would you like to attach any files?"
            fieldImportance="optional"
          >
            <JobNewBidFileDropField existing={existing} />
          </FieldSectionPanelWithImportance>
        </div>

        <div>
          <FieldArray
            name="new_application_answers"
            component={NewApplicationAnswersField}
          />
        </div>
      </div>
    </>
  );
};

type DetailsSubheaderProps = {
  job: Job | null | undefined;
};

const DetailsSubheader = ({ job }: DetailsSubheaderProps) => {
  if (!job) return null;

  return (
    <Box
      sx={{
        py: 2.5,
        px: { xs: 2, md: 7.5 },
        borderBottom: "1px solid var(--grey-4)",
        bgcolor: "var(--off-white)",
        display: "flex",
        justifyContent: "space-between",
        minWidth: "360px",
      }}
    >
      <Box
        display="flex"
        width={1}
        flexWrap={{ xs: "wrap", md: "nowrap" }}
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "flex-start", sm: "center" }}
        gap={{ xs: 2, sm: "1rem 2rem", lg: "1rem 4rem" }}
        margin="auto"
        maxWidth="1320px"
      >
        <BudgetDetail job={job} variant="details" shortened />
        <LocationDetail job={job} variant="details" />
        <TimezoneDetail job={job} variant="details" />
        <WorkTimeDetail job={job} variant="details" shortened />
        <DeadlineDetail job={job} variant="details" />
      </Box>
    </Box>
  );
};
