export const FETCH_FREELANCER_PROFILE = "FETCH_FREELANCER_PROFILE";
export const FETCH_FREELANCER_PROFILE_SUCCESS =
  "FETCH_FREELANCER_PROFILE_SUCCESS";
export const FETCH_FREELANCER_PROFILE_FAILED =
  "FETCH_FREELANCER_PROFILE_FAILED";
export const UPDATE_FREELANCER_PROFILE_SUCCESS =
  "UPDATE_FREELANCER_PROFILE_SUCCESS";
export const FETCH_FREELANCER_PUBLIC_PROFILE_SUCCESS =
  "FETCH_FREELANCER_PUBLIC_PROFILE_SUCCESS";
export const DELETE_WORK_SAMPLE = "DELETE_WORK_SAMPLE";
export const CREATE_WORK_SAMPLE_SUCCESS = "CREATE_WORK_SAMPLE_SUCCESS";
export const UPDATE_WORK_SAMPLE_SUCCESS = "UPDATE_WORK_SAMPLE_SUCCESS";
export const FETCH_FREELANCER_JOBS_SELECT = "FETCH_FREELANCER_JOBS_SELECT";
export const FETCH_FREELANCER_JOBS_SELECT_SUCCESS =
  "FETCH_FREELANCER_JOBS_SELECT_SUCCESS";
export const FETCH_FREELANCER_JOBS_SELECT_FAILED =
  "FETCH_FREELANCER_JOBS_SELECT_FAILED";
export const FETCHING_FREELANCER_OFFERS = "FETCHING_FREELANCER_OFFERS";
export const FETCHING_FREELANCER_OFFERS_SUCCESS =
  "FETCHING_FREELANCER_OFFERS_SUCCESS";
export const FETCHING_FREELANCER_OFFERS_FAILED =
  "FETCHING_FREELANCER_OFFERS_FAILED";
export const FETCHING_FREELANCER_EVENTS = "FETCHING_FREELANCER_EVENTS";
export const FETCHING_FREELANCER_EVENTS_SUCCESS =
  "FETCHING_FREELANCER_EVENTS_SUCCESS";
export const FETCHING_FREELANCER_EVENTS_FAILED =
  "FETCHING_FREELANCER_EVENTS_FAILED";
export const FETCHING_FREELANCER_ARTICLES = "FETCHING_FREELANCER_ARTICLES";
export const FETCHING_FREELANCER_ARTICLES_SUCCESS =
  "FETCHING_FREELANCER_ARTICLES_SUCCESS";
export const FETCHING_FREELANCER_ARTICLES_FAILED =
  "FETCHING_FREELANCER_ARTICLES_FAILED";
export const SKILLS_SAVED = "SKILLS_SAVED";
export const WORK_EXPERIENCE_ADDED = "WORK_EXPERIENCE_ADDED";
export const CERTIFICATE_ADDED = "CERTIFICATE_ADDED";
export const SCHOOL_ADDED = "SCHOOL_ADDED";
export const FREELANCER_PROFILE_VIEWED = "FREELANCER_PROFILE_VIEWED";
export const FREELANCER_PROFILE_CLICKED = "FREELANCER_PROFILE_CLICKED";
export const WORK_EXPERIENCE_EDITED = "WORK_EXPERIENCE_EDITED";
export const WORK_EXPERIENCE_DELETED = "WORK_EXPERIENCE_DELETED";
export const CERTIFICATE_EDITED = "CERTIFICATE_EDITED";
export const CERTIFICATE_DELETED = "CERTIFICATE_DELETED";
export const SCHOOL_EDITED = "SCHOOL_EDITED";
export const SCHOOL_DELETED = "SCHOOL_DELETED";
export const UPDATE_WORK_SAMPLE_POSITION = "UPDATE_WORK_SAMPLE_POSITION";
export const UPDATE_CERTIFICATES_POSITION = "UPDATE_CERTIFICATES_POSITION";
export const NAV_ITEM_CLICKED = "NAV_ITEM_CLICKED";
export const CLICK_TALENT_AFTER_SEARCH = "CLICK_TALENT_AFTER_SEARCH";
export const CLICK_INVITE_TALENT_AFTER_SEARCH =
  "CLICK_INVITE_TALENT_AFTER_SEARCH";
export const CLICK_AVAILABILITY_TOGGLE = "CLICK_AVAILABILITY_TOGGLE";
export const CLICK_CHECK_AVAILABILITY_OPTION =
  "CLICK_CHECK_AVAILABILITY_OPTION";
export const CLICK_SET_AVAILABILITY = "CLICK_SET_AVAILABILITY";
export const CLICK_UPDATE_AVAILABILITY_MENU = "CLICK_UPDATE_AVAILABILITY_MENU";
export const CLICK_UPDATE_AVAILABILITY_PROFILE =
  "CLICK_UPDATE_AVAILABILITY_PROFILE";
export const CLOSE_POST_ETIQUETTE_PANEL = "CLOSE_POST_ETIQUETTE_PANEL";
export const ADD_NOT_INTERESTED_JOB_ID = "ADD_NOT_INTERESTED_JOB_ID";
export const DELETE_NOT_INTERESTED_JOB_ID = "DELETE_NOT_INTERESTED_JOB_ID";
