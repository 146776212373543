import {
  AlignLeftIcon,
  ChatIcon,
  EarnIcon,
  JobsIcon,
  PeopleIcon,
  WalletIcon,
  WelcomeIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { WalletLabelWithTokenAmount } from "@js/apps/common/components/wallet-label-with-btrust-amount";
import { GiveGetHelpLabel } from "@js/apps/give-and-get-help/components/give-get-help-label";
import { SpacesNavLabel } from "@js/apps/spaces/components/spaces-nav-label";

import type { DashboardNavItem } from "../types";

export const getFreelancerNavMenuItems = (
  jobsPath: string,
): DashboardNavItem[] => {
  return [
    createNavItem({
      path: "/talent/dashboard/welcome/",
      ...FREELANCER_NAV_ITEMS.HOME,
    }),
    createNavItem({
      path: jobsPath,
      ...FREELANCER_NAV_ITEMS.JOBS,
    }),
    createNavItem({
      path: "/feed",
      ...FREELANCER_NAV_ITEMS.COMMUNITY,
      subLinks: [
        {
          ...createNavItem({
            path: "/spaces",
            ...FREELANCER_NAV_ITEMS.SPACES,
          }),
          defaultToActive: true,
          distinctPathFromParent: true,
        },
        {
          path: "my_posts",
          ...FREELANCER_NAV_ITEMS.MY_POSTS,
        },
      ],
    }),
    createNavItem({
      path: "/talent/dashboard/my_jobs",
      ...FREELANCER_NAV_ITEMS.MY_WORK,
      hideInSidebarNav: true,
      subLinks: [
        {
          path: "invites",
          label: "Invites",
        },
        {
          path: "my_proposals",
          label: "Applications",
          onlyActiveOnIndex: false,
          subPath: [
            "/talent/dashboard/my_jobs/my_proposals/open_applications",
            "/talent/dashboard/my_jobs/my_proposals/past_applications",
          ],
        },
        {
          path: "offers",
          label: "Job Offers",
          onlyActiveOnIndex: false,
          subPath: [
            "/talent/dashboard/my_jobs/offers/current_offers",
            "/talent/dashboard/my_jobs/offers/past_offers",
          ],
        },
        {
          path: "history",
          label: "My Jobs",
          onlyActiveOnIndex: false,
          subPath: [
            "/talent/dashboard/my_jobs/history/active_jobs",
            "/talent/dashboard/my_jobs/history/past_jobs",
          ],
        },
        {
          path: "help_offers",
          label: "1:1 Help Offers",
          onlyActiveOnIndex: false,
          subPath: [
            "/talent/dashboard/my_jobs/help_offers/getting_help",
            "/talent/dashboard/my_jobs/help_offers/giving_help",
          ],
        },
        {
          path: "invoices",
          label: "Invoices",
        },
      ],
    }),
    createNavItem({
      path: "/talent/dashboard/my_wallet/",
      ...FREELANCER_NAV_ITEMS.WALLET,
    }),
    createNavItem({
      path: "/talent/dashboard/earn/",
      ...FREELANCER_NAV_ITEMS.EARN,
    }),
  ];
};

const createNavItem = (arg: DashboardNavItem) => {
  return {
    icon: undefined,
    canAccess: true,
    hideInSidebarNav: false,
    defaultToActive: false,
    ...arg,
    subLinks: (arg.subLinks || []).map((link) => {
      return {
        ...link,
        path: `${link.distinctPathFromParent ? "" : arg.path + "/"}${link.path}`,
      };
    }),
  };
};

export const FREELANCER_NAV_ITEMS = {
  HOME: {
    label: "Home",
    icon: WelcomeIcon,
  },
  JOBS: {
    label: "Jobs",
    icon: JobsIcon,
  },
  COMMUNITY: {
    label: <GiveGetHelpLabel />,
    icon: ChatIcon,
  },
  SPACES: {
    label: <SpacesNavLabel />,
    icon: PeopleIcon,
  },
  MY_POSTS: {
    label: "My posts",
    icon: AlignLeftIcon,
  },
  MY_WORK: {
    label: "My Work",
  },
  WALLET: {
    label: <WalletLabelWithTokenAmount />,
    icon: WalletIcon,
  },
  EARN: {
    label: "Refer and Earn",
    icon: EarnIcon,
  },
};
