import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import type { JobTypeAndRoleTagProps } from "@js/apps/jobs/components/job-type-and-role-tags";
import { RolesTag } from "@js/apps/jobs/components/job-type-and-role-tags";
import { JobTypeTagWithTooltip } from "@js/apps/jobs/components/job-type-tag-with-tooltip";

import { JobItemInvitedByTag } from "../job-item/components/invited-by-tag";
import { JobItemSecondaryCaption } from "../job-item/components/secondary-caption";

export const JobTags = ({
  jobType,
  role,
  isInvitedByClient,
  isInvitedByMatcher,
  isInterested,
  createdTime,
}: JobTypeAndRoleTagProps) => {
  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" gap="8px">
      <JobTypeTagWithTooltip jobType={jobType} />
      <RolesTag role={role} />
      <JobItemInvitedByTag
        isInvitedByClient={isInvitedByClient}
        isInvitedByMatcher={isInvitedByMatcher}
        isInterested={isInterested}
      />
      <JobItemSecondaryCaption createdTime={createdTime} />
    </Box>
  );
};
