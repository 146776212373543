import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import {
  CertificatesModule,
  EducationModule,
  ExperienceModule,
  GetToKnowMeCard,
  GiveGetHelpProfileModule,
  TopSkillsSection,
  WorkHistoryModule,
} from "@js/apps/freelancer/components";
import { ProfileProjectsModule } from "@js/apps/freelancer/components/profile-projects-module";
import { TalentProfileReviewModule } from "@js/apps/reviews/components";
import { useAppSelector } from "@js/hooks";

import { MySpacesModule } from "../../components/my-spaces-module";

export const FreelancerProfilePageAbout = () => {
  const user = useUser();
  const profile = useAppSelector((state) => state.freelancer.publicProfile);
  const { isEmployer } = useAccountType();
  const workSampleItems = useAppSelector(
    (state) => state.freelancer.workSampleItems,
  );

  if (!profile) return null;

  return (
    <Box className="profile-page-content__details-section">
      <GetToKnowMeCard profile={profile} />
      <ExperienceModule profile={profile} />
      <TopSkillsSection profile={profile} user={user} chipColor="cool-grey" />
      <ProfileProjectsModule
        profile={profile}
        workSampleItems={workSampleItems}
      />
      <Box className="profile-page-content__details-section--bottom">
        <Box display="flex" flexDirection="column">
          {!isEmployer && <GiveGetHelpProfileModule profile={profile} />}
          <MySpacesModule spaces={profile.spaces} />
          <CertificatesModule profile={profile} />
        </Box>

        <Box>
          <WorkHistoryModule profile={profile} />
          <EducationModule profile={profile} />
          <TalentProfileReviewModule profile={profile} />
        </Box>
      </Box>
    </Box>
  );
};
