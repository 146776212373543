import { useEffect, useState } from "react";

import type {
  ComboBoxMultipleProps,
  WrappedFieldProps,
} from "@hexocean/braintrust-ui-components";
import {
  ComboBoxMultiple,
  TextField,
} from "@hexocean/braintrust-ui-components";
import type { UserSearchParams, UserSearchResult } from "@js/apps/auth/api";
import {
  useGetUserSearchQuery,
  useLazyGetUserSearchItemQuery,
} from "@js/apps/auth/api";

import { useSearchPhrase } from "../../hooks/search-phrase";

const useUserSearchMultiple = ({ input, meta, endpointParams }) => {
  const [value, setValue] = useState<UserSearchResult[]>([]);
  const [fetch, { isLoading }] = useLazyGetUserSearchItemQuery();
  const { onInputChange, searchPhrase } = useSearchPhrase();

  useEffect(() => {
    if (meta.initial?.length) {
      const promises = meta.initial.map((id) => {
        return fetch(id);
      });

      Promise.all(promises).then((values) => {
        const data = values
          .map((val) => val.data)
          .filter((item): item is UserSearchResult => !!item);
        setValue(data);
      });
    } else {
      setValue([]);
    }
  }, [fetch, meta.initial]);

  const { data } = useGetUserSearchQuery({
    search: searchPhrase,
    ...endpointParams,
  });

  useEffect(() => {
    if (!input.value || !input.value?.length) {
      setValue([]);
    }
  }, [input.value]);

  const onChange = (_ev, valueArg) => {
    setValue(valueArg);
    input.onChange(valueArg.map((item) => item.id));
  };

  return {
    options: data || [],
    value,
    isLoading,
    onInputChange,
    onChange,
  };
};

type UserSearchMultipleFieldProps = WrappedFieldProps & {
  meta: { initial: number[] };
} & Partial<
    Omit<ComboBoxMultipleProps<UserSearchResult, false>, "component">
  > & {
    label?: string;
    endpointParams?: Partial<UserSearchParams>;
    placeholder?: string;
  };

export const UserSearchMultipleStandardField = ({
  endpointParams,
  label,
  input,
  meta,
}: UserSearchMultipleFieldProps) => {
  const { options, value, isLoading, onInputChange, onChange } =
    useUserSearchMultiple({
      input,
      meta,
      endpointParams,
    });

  return (
    <ComboBoxMultiple<UserSearchResult, false>
      onInputChange={onInputChange}
      initialTaxonomiesLoading={isLoading}
      options={options}
      renderInput={(params) => {
        return (
          <TextField
            label={label || "User"}
            {...params}
            variant="standard"
            error={!!meta.error}
            helperText={(meta.error as string) || ""}
          />
        );
      }}
      value={value}
      onChange={onChange}
      getOptionLabel={(option) => option.name_and_email}
    />
  );
};

export const UserSearchMultipleField = ({
  endpointParams,
  label,
  placeholder,
  input,
  meta,
}: UserSearchMultipleFieldProps) => {
  const { options, value, isLoading, onInputChange, onChange } =
    useUserSearchMultiple({
      input,
      meta,
      endpointParams,
    });

  return (
    <ComboBoxMultiple<UserSearchResult, false>
      onInputChange={onInputChange}
      initialTaxonomiesLoading={isLoading}
      options={options}
      renderInput={(params) => {
        return (
          <TextField
            label={label || "Users"}
            placeholder={placeholder}
            {...params}
            error={!!meta.error}
            helperText={(meta.error as string) || ""}
          />
        );
      }}
      value={value}
      onChange={onChange}
      getOptionLabel={(option) => option.name_and_email}
      sx={{
        ".MuiAutocomplete-tag": {
          maxWidth: "75%",
        },
      }}
    />
  );
};
