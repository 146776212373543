import type { OnboardingOrganizationFields } from "./components/organization/schema";

const EMPLOYER_SIGN_UP_PARAMS = [
  "secret",
  "company_node",
  "invitation_key",
] as const;

export const isEmployerParamUsed = (params: URLSearchParams) => {
  return EMPLOYER_SIGN_UP_PARAMS.some((param) => params.has(param));
};

export const prepareOrganizationInfoValues = (
  values: OnboardingOrganizationFields,
) => {
  const newValues = { ...values };
  if (typeof newValues.google_full_location === "string") {
    delete newValues.google_full_location;
  }
  return newValues;
};
