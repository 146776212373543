import classNames from "classnames";

import { Box } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks/use-user";
import { NotLoggedInApplyButton } from "@js/apps/jobs/components//not-logged-in-apply-button";
import { ViewJobButton } from "@js/apps/jobs/components//view-job-button";
import { ApplicationBoostFeatureFlagWrapper } from "@js/apps/jobs/components/application-boost-feature-flag-wrapper/index";
import { BoostJobButton } from "@js/apps/jobs/components/boost-job-button/index";
import { NotInterestedButton } from "@js/apps/jobs/components/not-interested-button";
import type { MainJobListingJob } from "@js/types/jobs";

import styles from "./styles.module.scss";

type JobItemButtonsProps = {
  isTablet: boolean;
  isMobile: boolean;
  isInvitesTab: boolean;
  isPublic?: boolean;
  job: MainJobListingJob;
  isSearchResults?: boolean;
  resultPosition?: number;
};

export const JobItemButtons = ({
  isTablet,
  isMobile,
  isInvitesTab,
  job,
  isSearchResults,
  resultPosition,
  isPublic = false,
}: JobItemButtonsProps) => {
  const user = useUser();
  const alreadyBoosted = job?.is_boosted ? job?.is_boosted : false;

  return (
    <Box
      className={classNames(styles.wrapper, {
        [styles.invitesTab]: isTablet && isInvitesTab,
      })}
    >
      <NotInterestedButton job={job} fullWidth />
      {isPublic && (
        <NotLoggedInApplyButton
          jobId={job.id}
          variant="secondary"
          fullWidth={isMobile}
          sx={{ height: "40px" }}
        />
      )}
      <ApplicationBoostFeatureFlagWrapper>
        {job.has_freelancer_bid && (
          <BoostJobButton
            variant="secondary"
            color="secondary"
            boostCredit={user?.boost_credit}
            bidId={job?.bid_id}
            fullWidth={isMobile}
            sx={{
              display: { xs: "none", sm: "flex" },
              flexShrink: 0,
              height: 40,
            }}
            isOpen={true}
            alreadyBoosted={alreadyBoosted}
          />
        )}
      </ApplicationBoostFeatureFlagWrapper>

      <ViewJobButton
        job={job}
        variant="primary"
        color="primary"
        fullWidth={isMobile}
        sx={{
          display: { xs: "none", sm: "flex" },
          flexShrink: 0,
          height: 40,
        }}
        resultPosition={resultPosition}
        searchResults={isSearchResults}
      />
    </Box>
  );
};
