import { trimURL } from "@js/utils/url";

import type { DashboardNavItem } from "../types";

import { EMPLOYER_NAV_ITEMS } from "./employer-navigation";
import { FREELANCER_NAV_ITEMS } from "./freelancer-navigation";

export const findCurrentNavItem = ({
  items,
  exact,
}: {
  items: DashboardNavItem[];
  exact?: boolean;
}) => {
  const linkItem = items.find(({ path }) => {
    const currentPathnameTrimmed = trimURL(window.location.pathname);
    const linkPathnameTrimmed = trimURL(path).split("?")[0];
    if (exact) {
      return currentPathnameTrimmed === linkPathnameTrimmed;
    }

    return currentPathnameTrimmed.includes(linkPathnameTrimmed);
  });

  return linkItem;
};

export const getNavMenuColorVariant = (currentItem?: DashboardNavItem) => {
  if (!currentItem) {
    return getNavMenuColorForPathname(window.location.pathname);
  }

  switch (currentItem.label) {
    case FREELANCER_NAV_ITEMS.HOME.label:
      return "nav-violet";
    case EMPLOYER_NAV_ITEMS.TALENT.label:
    case EMPLOYER_NAV_ITEMS.MY_JOBS.label:
    case FREELANCER_NAV_ITEMS.JOBS.label: {
      return "nav-blue";
    }
    case FREELANCER_NAV_ITEMS.SPACES.label:
    case FREELANCER_NAV_ITEMS.COMMUNITY.label:
    case FREELANCER_NAV_ITEMS.WALLET.label:
      return "nav-green";
    case EMPLOYER_NAV_ITEMS.INVOICES.label:
    case FREELANCER_NAV_ITEMS.EARN.label:
    default:
      return "nav-default-white";
  }
};

const pathnameColorVariants = [
  { regexp: /^(\/talent\/\d+)/, variant: "nav-violet" },
  { regexp: /\/jobs\/\d+\/offers\/\d+\/accepted\/?$/, variant: "nav-green" },
  { regexp: /^(\/talent\/?$|\/jobs\/?|\/employers\/\d+)/, variant: "nav-blue" },
  { regexp: /^(\/feed\/?|\/spaces\/?)/, variant: "nav-green" },
  { regexp: /^(\/messages\/?)/, variant: "nav-orange" },
];
const getNavMenuColorForPathname = (pathname: string) => {
  const matchingVariantData = pathnameColorVariants.find(({ regexp }) =>
    regexp.test(pathname),
  );

  return matchingVariantData?.variant ?? "nav-default-white";
};

export const checkIfAnySubLinkIsActive = (subLinks: DashboardNavItem[]) => {
  const subLinksWithNestedSubLinks = subLinks.filter(
    (subLink) => subLink.subLinks,
  ) as unknown as Required<DashboardNavItem>[];
  let flag = false;
  if (subLinksWithNestedSubLinks.length) {
    subLinksWithNestedSubLinks.forEach((subLink) => {
      flag = flag || !!findCurrentNavItem({ items: subLink.subLinks });
    });
  }
  return flag || !!findCurrentNavItem({ items: subLinks });
};
