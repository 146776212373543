import _ from "underscore";

import type { TalentMixedLocation } from "@js/apps/freelancer/types";
import { LOCATIONS_TAGS_CHARACTERS_LIMIT } from "@js/apps/jobs/apps/create-job/constants";
import { mapLocationsToBackendFormat } from "@js/apps/jobs/apps/create-job/utils/locations-mapping";
import { mapRateValuesToBackendFormat } from "@js/apps/jobs/apps/create-job/utils/rate-mapping";
import { sanitizeRichEditorContent } from "@js/apps/jobs/apps/create-job/utils/sanitize";
import { mapTimezonesToBackendFormat } from "@js/apps/jobs/apps/create-job/utils/timezones-mapping";
import type {
  CreateJobData,
  CreateJobDraftPayload,
  GetJobDraftResponse,
  JobDraft,
  JobFormValues,
  PreparedJobDraftData,
  TimezoneOption,
  UpdateJobData,
  UpdateJobDraftPayload,
} from "@js/types/jobs";
import { deepClone } from "@js/utils";

const removeUndefinedKeys = <T extends object>(obj: T): T => {
  return Object.keys(obj).reduce((prev, current) => {
    if (obj[current] !== undefined) {
      prev[current] = obj[current];
    }

    return prev;
  }, {} as T);
};

export const prepareDraftResponse = (
  response: GetJobDraftResponse,
): JobDraft => {
  const responseWithoutData: Omit<GetJobDraftResponse, "data"> & {
    data?: undefined;
  } = {
    ...response,
    data: undefined,
  };

  delete responseWithoutData.data;

  return {
    ...responseWithoutData,
    ...response.data,
  };
};
export const prepareJobDraftFormValues = (
  valuesArg: JobFormValues,
): PreparedJobDraftData => {
  const values: PreparedJobDraftData = deepClone({
    ...mapRateValuesToBackendFormat<JobFormValues>(valuesArg),
    jobOwnerId: undefined,
    isDeletingJobDraft: undefined,
    ats_imported: undefined,
    timezones: valuesArg.timezones?.length
      ? mapTimezonesToBackendFormat(valuesArg.timezones)
      : [],
    locations: valuesArg.locations?.length
      ? mapLocationsToBackendFormat(valuesArg.locations)
      : [],
  });

  if (!values.timezones.length) {
    values.timezone_overlap = null;
  }

  if (!values.locations.length) {
    values.locations_strongly_required = false;
  }

  values.op_owners =
    !!values.op_owners && values.op_owners.length > 0
      ? values.op_owners.map((owner) => owner)
      : [];
  values.sale_owners =
    !!values.sale_owners && values.sale_owners.length > 0
      ? values.sale_owners.map((owner) => owner)
      : [];

  return values;
};
export const prepareLatestDraftDataToSave = (
  values: JobFormValues,
  lastSavedDraft: JobDraft | undefined,
): PreparedJobDraftData | undefined => {
  const valuesCopy = deepClone(values);

  const filteredValues = removeUndefinedKeys(
    prepareJobDraftFormValues(valuesCopy),
  );

  const toCompare = _.pick(lastSavedDraft, Object.keys(filteredValues)) ?? {};

  // If you wonder why there is additional request after creating a new draft:
  // It is because we initialize form with values depending on job_type (e.g. contract_type).
  // To avoid request we would need to submit those values on draft set up.
  // Form values are sanitized to avoid unnecessary requests when rich editor field doesn't contain content
  // but editor itself fills value with HTML tags (f.e. only white spaces or enters)

  const areEqual = _.isEqual(
    sanitizeRichEditorContent(filteredValues),
    sanitizeRichEditorContent(toCompare),
  );

  if (areEqual) {
    return;
  }

  return filteredValues;
};
const withoutId = <T extends { id: unknown }>(arg: T): Omit<T, "id"> => {
  const argWithoutId: Omit<T, "id"> & { id?: unknown } = { ...arg };
  delete argWithoutId.id;

  return argWithoutId;
};

export const prepareCreateJobSubmitFormValues = (
  valuesArg: JobFormValues,
): CreateJobData | undefined => {
  const jobDraftId = valuesArg.id;
  if (!jobDraftId) {
    return undefined;
  }
  const preparedDraftValues = prepareJobDraftFormValues(valuesArg);
  const preparedDraftValuesWithoutId = withoutId(preparedDraftValues);

  return { ...preparedDraftValuesWithoutId, job_draft_id: jobDraftId };
};

export const prepareEditJobSubmitFormValues = (
  valuesArg: JobFormValues,
  isEmployer: boolean | undefined,
): UpdateJobData => {
  const preparedValues = prepareJobDraftFormValues(valuesArg);

  const updateJobData = {
    ..._.pick(preparedValues, [
      "title",
      "contract_type",
      "deadline",
      "start_date",
      "expected_hours_per_week",
      "openings_number",
      "role",
      "introduction",
      "description",
      "requirements",
      "budget_maximum_usd",
      "budget_minimum_usd",
      "level",
      "payment_type",
      "new_application_questions",
      "auto_invite_talent",
      "locations",
      "locations_strongly_required",
      "timezones",
      "timezone_overlap",
      "new_skills",
      "top_skills",
      "is_private",
      "experience_level",
      "is_resume_required",
      "job_subscribers",
      ...(isEmployer ? ["job_subscriber_invitations"] : []),
      "is_ai_generated",
    ]),
  } as UpdateJobData;

  return updateJobData;
};

export type PrepareArrayWithTagsCharactersLimitEntity =
  | TimezoneOption
  | TalentMixedLocation;

export const prepareArrayWithTagsCharactersLimit = <
  T extends PrepareArrayWithTagsCharactersLimitEntity,
>(
  entities: T[],
  tagsCharactersLimit: number,
  getName: (item: T) => string,
  focused: boolean,
): T[] => {
  if (focused) return entities;

  let lengthCounter = 0;

  return entities.reduce((accumulator: T[], currentItem: T) => {
    const itemName = getName(currentItem);

    const hasExceededCharacterLimit =
      lengthCounter + itemName.length >= tagsCharactersLimit;

    lengthCounter += itemName.length;

    if (hasExceededCharacterLimit && accumulator.length > 0) {
      return accumulator;
    }

    return [...accumulator, currentItem];
  }, []);
};

export const prepareAutocompleteValue = ({
  value,
  isLast,
  isInputFocused,
  isLimitReached,
  limit = LOCATIONS_TAGS_CHARACTERS_LIMIT,
}: {
  value: string;
  isLast: boolean;
  isInputFocused: boolean;
  isLimitReached: boolean;
  limit?: number;
}): JSX.Element => {
  let ellipsis = "";
  let processedValue = value;

  if (!isLast || isInputFocused) {
    ellipsis = ` |`;
  } else if (isLimitReached) {
    ellipsis = ` | ...`;
  }

  if (processedValue.length > limit && !isInputFocused) {
    processedValue = processedValue.slice(0, limit);
    ellipsis = "...";
  }

  return (
    <span
      key={value}
      style={{
        marginRight: "5px",
      }}
    >
      {`${processedValue}${ellipsis}`}
    </span>
  );
};

type SaveDraftArg = {
  data: Optional<PreparedJobDraftData, "id">;
  original_job?: number | null;
};

type SaveDraftPayload = UpdateJobDraftPayload | CreateJobDraftPayload;

export const prepareJobDraftPayload = (
  draftDataToSave: SaveDraftArg,
): SaveDraftPayload => {
  const draftDataToSaveClone = deepClone(draftDataToSave);
  const preparedJobDraftPayloadData: SaveDraftPayload["data"] & {
    job_subscribers?: undefined;
    job_subscriber_invitations?: undefined;
  } = {
    ...draftDataToSaveClone.data,
    job_subscribers: undefined,
    job_subscriber_invitations: undefined,
  };

  delete preparedJobDraftPayloadData.job_subscribers;
  delete preparedJobDraftPayloadData.job_subscriber_invitations;

  return {
    ...draftDataToSaveClone,
    data: preparedJobDraftPayloadData,
    job_subscribers: draftDataToSaveClone.data.job_subscribers || [],
    job_subscriber_invitations:
      draftDataToSaveClone.data.job_subscriber_invitations || [],
  };
};
