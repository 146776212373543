import { fetchFreelancerPublicProfile } from "@js/apps/freelancer/actions";
import { useCompleteHelpOfferMutation } from "@js/apps/give-and-get-help/api";
import { CompleteHelpOfferModal } from "@js/apps/messenger/components/complete-help-offer-modal";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { HelpOffer } from "@js/types/give-and-get-help";

type UseCompleteHelpOfferConfig = {
  offer: HelpOffer;
};

export const useCompleteHelpOffer = ({ offer }: UseCompleteHelpOfferConfig) => {
  const [completeOffer, { isLoading: isAccepting }] =
    useCompleteHelpOfferMutation();
  const dispatch = useAppDispatch();

  const completeOfferHandler = () => {
    completeOffer({ offerId: offer.id, data: { budget: String(offer.budget) } })
      .unwrap()
      .then(() => {
        //required to refetch profile.can_user_review_help_offer flag on talent profile page in order to display reviews section
        dispatch(fetchFreelancerPublicProfile(offer.author.id));
        Snackbar.success("Offer marked as complete");
      })
      .catch(() => Snackbar.error("Something went wrong."))
      .finally(() => ModalInstance.close());
  };

  const handleCompleteOffer = () => {
    ModalInstance.open({
      closeButton: true,
      closeButtonProps: {
        variant: "tertiary",
        size: "x-small",
      },
      keepOnBackdropClick: true,
      className: "accept-offer-modal",
      children: (
        <CompleteHelpOfferModal
          offerAuthorFirstName={offer.author.user.first_name}
          completeOffer={completeOfferHandler}
        />
      ),
    });
  };

  return {
    handleCompleteOffer,
    isAccepting,
  };
};
