import { useCallback, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { FreelancerRole } from "@js/types/freelancer";

import {
  fetchFreelancerProfile,
  fetchFreelancerPublicProfile,
} from "../../actions";
import { useGetFreelancerRolesQuery } from "../../api";
import type { RolesFormData } from "../../forms/role-form";

type RoleModalState =
  | {
      kind: "list";
    }
  | {
      kind: "add";
      initialValues?: RolesFormData;
    }
  | {
      kind: "edit";
      initialValues: RolesFormData;
    }
  | {
      kind: "remove";
      role: FreelancerRole;
    };

export const useRoleModal = () => {
  const { data: roles = [], isLoading } = useGetFreelancerRolesQuery();
  const freelancerProfile = useAppSelector(
    (state) => state.freelancer.freelancerProfile,
  );
  const dispatch = useAppDispatch();
  const [modal, setModal] = useState<RoleModalState>({ kind: "list" });
  const remainingRoleCount = SETTINGS.FREELANCER_ROLES_MAX - roles.length;
  const maxRoleCountReached = remainingRoleCount === 0;
  const canSaveAndAddAnother = remainingRoleCount >= 2;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (modal.kind === "list" && !roles?.length) {
      setModal({ kind: "add", initialValues: { primary: true } });
    }
  }, [modal.kind, roles, isLoading]);

  useEffect(() => {
    if (modal.kind === "add" && maxRoleCountReached) {
      setModal({ kind: "list" });
    }
  }, [modal.kind, maxRoleCountReached, setModal]);

  const onRoleChange = useCallback(() => {
    if (!freelancerProfile?.id) return null;

    dispatch(fetchFreelancerProfile(freelancerProfile?.id));
    dispatch(fetchFreelancerPublicProfile(freelancerProfile?.id));
  }, [freelancerProfile?.id, dispatch]);

  const handleAdd = useCallback(() => {
    setModal({ kind: "add" });
  }, []);

  const handleEdit = useCallback((role: RolesFormData) => {
    setModal({ kind: "list" });
    setTimeout(() => {
      setModal({ kind: "edit", initialValues: role });
    });
  }, []);

  const handleDelete = useCallback((role: FreelancerRole) => {
    setModal({ kind: "remove", role });
  }, []);

  const handleSaveRole = useCallback(
    (addAnother?: boolean) => {
      if (addAnother) {
        setModal({ kind: "add" });
      } else {
        setModal({ kind: "list" });
      }
      onRoleChange();
    },
    [onRoleChange],
  );

  const handleCancel = useCallback((addAnother?: boolean) => {
    if (addAnother) {
      setModal({ kind: "add" });
    } else {
      setModal({ kind: "list" });
    }
  }, []);

  return {
    roles,
    modal,
    canSaveAndAddAnother,
    maxRoleCountReached,
    handleAdd,
    handleEdit,
    handleDelete,
    handleSaveRole,
    handleCancel,
  };
};
