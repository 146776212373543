import { Navigate, Outlet } from "react-router-dom";
import cs from "classnames";

import {
  Alert,
  Loader,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { showAlert } from "@hexocean/braintrust-ui-components/utils";
import { ACCESS_DENIED } from "@js/apps/common/constants";
import { useUser } from "@js/apps/common/hooks/use-user";
import { JobEmployerDetails } from "@js/apps/employer/components";
import { SimilarJobsSection } from "@js/apps/jobs/components";
import { ExperienceBox } from "@js/apps/jobs/components/experience-box";
import { Rate } from "@js/apps/jobs/components/rate";
import { RoleAndJobTypeBox } from "@js/apps/jobs/components/role-and-job-type-box";
import { ScreeningCard } from "@js/apps/jobs/components/screening-card";
import { JOB_LOCATION, JobLocationContext } from "@js/apps/jobs/context";
import { AttachmentList } from "@js/components/attachment-list";
import { PageTitle } from "@js/components/page-title";
import { useAppDispatch } from "@js/hooks";
import { globalTimeoutsDelays, useTimeout } from "@js/hooks/timeout";
import { useIdParam } from "@js/hooks/use-id-param";
import { AppLayout } from "@js/layouts/app";

import { viewJobDetails } from "../../actions";
import {
  JobDetailsBasicDetails,
  JobHeader,
  LocationRequirementWarning,
  MatchersListing,
  ShareThisJobBubble,
  Status,
} from "../../components";
import { InvitationMessageBox } from "../../components/invitation-message-box";
import { JobDescription } from "../../components/job-description";
import { useJobDetails } from "../../hooks";
import { isJobCompleted } from "../../utils";
import { PublicJobDetails } from "../public-job-details";

type JobDetailsProps = {
  jobId: number;
};

const JobDetailsWrapper = () => {
  const user = useUser();
  const jobId = useIdParam();

  if (!jobId) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  if (!user) {
    return <PublicJobDetails jobId={jobId} />;
  }

  return <JobDetails jobId={jobId} />;
};

JobDetailsWrapper.HTTP_404_ID = ENUMS.JobNotFoundErrorCode.JOB_NOT_FOUND;
JobDetailsWrapper.HTTP_403_ID = ACCESS_DENIED.JOB_ACCESS_DENIED;

const JobDetails = ({ jobId }: JobDetailsProps) => {
  const dispatch = useAppDispatch();
  const { loading, job, bid, employerProfile: employer } = useJobDetails(jobId);

  useTimeout(
    () => {
      if (!jobId) {
        return;
      }
      dispatch(
        viewJobDetails({
          source: JOB_LOCATION.job_details,
          jobUrl: window.location.href,
          jobId,
        }),
      );
    },
    globalTimeoutsDelays.log_view_event,
    [dispatch, jobId],
  );

  const isCompleted = !!job && isJobCompleted(job);
  const displayShareJobBubble = !isCompleted && !!job?.openings_left;
  const isInvited = !!job?.invited_by_client || !!job?.invited_by_matcher;
  const hasFreelancerBid = job?.has_freelancer_bid;
  const hideScreeningCard = job?.job_type === ENUMS.JobType.GRANT;

  return (
    <JobLocationContext.Provider value={JobLocationContext.Values.job_details}>
      <AppLayout className="wider job-details-page" bgcolor="var(--soft-blue)">
        {loading || !job ? (
          <Loader />
        ) : (
          job && (
            <>
              <PageTitle>{`Job: ${job.title}`}</PageTitle>
              {!!job.referring_user &&
                showAlert(
                  <Alert type="info" withIcon>
                    <Typography
                      component="p"
                      variant="paragraph"
                      size="medium"
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      <Typography component="span" fontWeight={500}>
                        {job.referring_user}
                      </Typography>{" "}
                      referred you for this job, please review and place an
                      application if you think you’d be a good fit!
                    </Typography>
                  </Alert>,
                )}
              <main>
                <JobHeader
                  job={job}
                  bid={bid}
                  className="job-details-page__top-section"
                  withDetailsHeader
                />
                <section
                  className={cs("job-details-page__details", {
                    "job-details-page__details--without-seniority":
                      !job.experience_level && !job.level,
                  })}
                >
                  <div className="job-details-page__rate">
                    <Rate job={job} bid={bid} />
                  </div>
                  <div className="job-details-page__basic-details">
                    <JobDetailsBasicDetails
                      className="job-details-page__basic-details-wrapper"
                      job={job}
                    />
                  </div>
                  <div className="job-details-page__warning-badge">
                    <LocationRequirementWarning job={job} />
                  </div>
                  <div className="job-details-page__seniority">
                    <ExperienceBox
                      experienceAsArrow={job.experience_as_arrow}
                      experienceLevel={job.experience_level}
                      jobRole={job.role}
                      level={job.level}
                    />
                  </div>
                  <div className="job-details-page__top-skills">
                    <RoleAndJobTypeBox
                      jobType={job.job_type}
                      jobRole={job.role}
                    />
                  </div>
                  <div className="job-details-page__status-box">
                    <Status
                      job={job}
                      bid={bid}
                      className="job-details-page__status"
                      fullSize={!job.has_freelancer_bid}
                    />
                  </div>
                </section>
                {displayShareJobBubble && (
                  <div className="job-details-page__mobile-refer">
                    <ShareThisJobBubble job={job} />
                  </div>
                )}
                <section className="job-details-page__content">
                  <section style={{ overflow: "hidden" }}>
                    <JobDescription job={job} />
                    {!!job.attachments.length && (
                      <RoundedBox mt={3}>
                        <AttachmentList attachments={job.attachments} />
                      </RoundedBox>
                    )}
                    <div className="job-details-page__bottom-status-box">
                      <Status
                        job={job}
                        bid={bid}
                        longTile
                        fullSize={!job.has_freelancer_bid}
                      />
                    </div>
                  </section>
                  <section className="job-details-page__employer-column">
                    {!hideScreeningCard && <ScreeningCard />}
                    {isInvited && job.interested && !isCompleted && (
                      <div className="job-details-page__invitation_box">
                        <InvitationMessageBox
                          job={job}
                          bid={bid}
                          hasFreelancerBid={hasFreelancerBid}
                        />
                      </div>
                    )}
                    {displayShareJobBubble && (
                      <div className="job-details-page__refer">
                        <ShareThisJobBubble job={job} />
                      </div>
                    )}
                    <div className="job-details-page__employer">
                      {!employer ? (
                        <Loader />
                      ) : (
                        <RoundedBox>
                          <JobEmployerDetails
                            employer={employer}
                            jobId={job.id}
                          />
                        </RoundedBox>
                      )}
                    </div>
                    <div>
                      <MatchersListing job={job} />
                    </div>
                    <div className="job-details-page__bottom-rate">
                      <Rate job={job} bid={bid} isCompleted={isCompleted} />
                    </div>
                  </section>
                  {displayShareJobBubble && (
                    <div className="job-details-page__mobile-refer">
                      <ShareThisJobBubble job={job} />
                    </div>
                  )}
                </section>
              </main>
            </>
          )
        )}
        <div className="job-details-page__similar-jobs">
          <SimilarJobsSection jobId={jobId} />
        </div>
        <Outlet />
      </AppLayout>
    </JobLocationContext.Provider>
  );
};

export default JobDetailsWrapper;
