import type { Location, Params } from "react-router";
import amplitude from "amplitude-js";
import Cookies from "js-cookie";

import { replaceAllMultipleSlashesWithSingleSlash } from "@js/routes/gather-routes";
import { mapRouteToViewEvent } from "@js/routes/gather-routes/map-route-to-view-event";
import type { LogViewEventProperties } from "@js/services/analytics/event-logging";
import { stripSlashes } from "@js/utils";
import { deepClone } from "@js/utils/data";

import type { AccountType } from "../../types/auth";

import { Events, EventsProperties } from "./constants";

export const eventPropertiesMiddleware = (
  event: EnumType<typeof Events>,
  eventProperties?: Record<string, unknown>,
) => {
  const eventPropertiesWithHyperLink = withHyperlink(eventProperties);

  switch (event) {
    case Events.SELECT_SIGN_UP_REASONS: {
      const mappedReasons = mapSignUpReasonsToAnalyticsFormat(
        eventProperties?.[EventsProperties.sign_up_reasons] as string[],
      );
      return {
        ...eventProperties,
        [EventsProperties.sign_up_reasons]: mappedReasons,
      };
    }

    case Events.SELECT_ACCOUNT_TYPE: {
      const mappedAccountType = mapAccountTypeToAnalyticsFormat(
        eventProperties?.[EventsProperties.account_type] as AccountType,
      );
      return {
        ...eventProperties,
        [EventsProperties.account_type]: mappedAccountType,
      };
    }

    default:
      return eventPropertiesWithHyperLink;
  }
};

const mapAccountTypeToAnalyticsFormat = (accountType: AccountType): string => {
  if (accountType === ENUMS.AccountType.FREELANCER) {
    return "talent";
  }

  return accountType;
};

const mapSignUpReasonsToAnalyticsFormat = (
  signUpReasons: Array<string>,
): Array<string> => {
  const newReasons: Array<string> = [];

  if (
    signUpReasons.some(
      (reason) =>
        reason === "is_looking_for_freelance_projects" ||
        reason === "is_looking_for_contract_to_hire_roles",
    )
  ) {
    newReasons.push("Work");
  }

  if (
    signUpReasons.some((reason) => reason === "wants_connect_with_community")
  ) {
    newReasons.push("Connect");
  }

  if (signUpReasons.some((reason) => reason === "wants_earn_btrusts")) {
    newReasons.push("Earn");
  }

  if (signUpReasons.some((reason) => reason === "wants_learn_new_skills")) {
    newReasons.push("Learn");
  }

  return newReasons;
};

export const sanitizePathname = (pathname: string): string => {
  const pathnameWithDeduplicatedSlashes =
    replaceAllMultipleSlashesWithSingleSlash(pathname);

  const pathnameWithoutLeadingAndTrailingSlashes = stripSlashes(
    pathnameWithDeduplicatedSlashes,
  );

  return pathnameWithoutLeadingAndTrailingSlashes.toLowerCase();
};

const PARAMS_TO_IGNORE = ["*"];

export const transformPathname = (
  initialPathname: string,
  params: Params,
): string => {
  let transformedPathname = initialPathname;
  for (const paramName in params) {
    if (PARAMS_TO_IGNORE.includes(paramName)) {
      continue;
    }
    const paramValue = params[paramName];
    if (paramValue) {
      transformedPathname = transformedPathname.replace(
        paramValue.toLowerCase(),
        `:${paramName}`,
      );
    }
  }
  return transformedPathname;
};

export const prepareViewEventProperties = (
  location: Location,
  searchParams: URLSearchParams,
  params: Params,
): LogViewEventProperties => {
  const searchParamsObject = Object.fromEntries(searchParams.entries());
  const paramsObject = deepClone<any>(params);
  delete paramsObject["*"];
  return {
    hyperlink: `${location.pathname}${location.search}${location.hash}`,
    ...paramsObject,
    ...location.state,
    ...searchParamsObject,
  };
};

type ViewEventLoggingData = {
  event: string;
  eventProperties: LogViewEventProperties;
};

export const prepareDataForViewEventLogging = (
  location: Location,
  searchParams: URLSearchParams,
  params: Params,
): ViewEventLoggingData => {
  const sanitizedPathname = sanitizePathname(location.pathname);
  const transformedPathname = transformPathname(sanitizedPathname, params);
  const event = mapRouteToViewEvent(transformedPathname);
  const eventProperties = prepareViewEventProperties(
    location,
    searchParams,
    params,
  );

  return { event, eventProperties };
};

const withHyperlink = (eventProperties?: Record<string, unknown>) => {
  const location = window.location;
  const hyperlink = `${location.pathname}${location.search}${location.hash}`;

  return {
    ...eventProperties,
    hyperlink,
  };
};

export const deleteAmplitudeCookies = (): void => {
  Object.keys(Cookies.get())
    .filter((c) => c.startsWith("amp_"))
    .forEach((el) => Cookies.remove(el));
};

export const setDeviceId = (deviceId: string) =>
  amplitude.getInstance().setDeviceId(deviceId);

export const pruneUnsentTrackingEvents = () => {
  const instance = amplitude.getInstance();
  // There isn't a dedicated callback in the AmplitudeClient API so we need to ...
  // ... manually override the private _unsentEvents property
  if (!("_unsentEvents" in instance)) {
    return;
  }

  instance._unsentEvents = [];
};
