import * as React from "react";

import { useUser } from "@js/apps/common/hooks";

import DashboardEmployerLayout from "../../employer/views/dashboard/layout";
import DashboardFreelancerLayout from "../../freelancer/views/dashboard/layout";

export const FreelancerOrEmployerDashboardLayout = ({ children, ...rest }) => {
  const user = useUser();

  if (!user) {
    throw Error("User data is missing");
  }

  const DashboardLayout = user.freelancer
    ? DashboardFreelancerLayout
    : DashboardEmployerLayout;

  return <DashboardLayout {...rest}>{children}</DashboardLayout>;
};
