import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";

type ScoreMatchBadgeProps = {
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null;
};

export const ScoreMatchBadge = ({ matchLabel }: ScoreMatchBadgeProps) => {
  const { user, showBadge } = useScoreMatchBadge({
    matchLabel,
  });

  if (!user || !showBadge || !matchLabel) {
    return null;
  }

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        marginTop: "8px",
      }}
    >
      <Typography
        mr={2}
        color="positive"
        fontWeight={500}
        component="span"
        variant="paragraph"
        size="small"
      >
        {formatMatchLabel(matchLabel)}
      </Typography>
    </Box>
  );
};

const useScoreMatchBadge = ({ matchLabel }: ScoreMatchBadgeProps) => {
  const user = useUser();
  const showBadge = matchLabel !== ENUMS.SmartMatchingLabel.BAD;

  return {
    user,
    showBadge,
  };
};

const formatMatchLabel = (
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null,
) => {
  if (!matchLabel) return "";

  const label = ENUMS.SmartMatchingLabels[matchLabel];

  if (
    label === ENUMS.SmartMatchingLabels.good ||
    label === ENUMS.SmartMatchingLabels.great
  ) {
    return `${label}!`;
  }

  return label;
};
