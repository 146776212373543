import React from "react";

import { Button, IconButton } from "@hexocean/braintrust-ui-components";
import { MessageIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import {
  FreelancerOfferStatusCaption,
  OfferDate,
} from "@js/apps/freelancer/components";
import { MyWorkBaseCard } from "@js/apps/freelancer/components";
import { PaymentAmount } from "@js/apps/freelancer/components/my-work-base-component/components";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import { RouterLink } from "@js/components/link";
import type { FreelancerOffer } from "@js/types/jobs";

export const OfferItem = ({ offer }: { offer: FreelancerOffer }) => {
  const user = useUser();
  const { openMessengerModal } = useOpenMessengerModal();

  return (
    <MyWorkBaseCard
      job={offer.job}
      topStatusTag={<FreelancerOfferStatusCaption offerStatus={offer.status} />}
      bottomSection={
        <PaymentAmount
          paymentAmount={offer.payment_amount}
          paymentType={offer.payment_type}
          variant="title"
          fontWeight={400}
          mt="6px"
          size="small"
        />
      }
      rightSection={
        <div className="my-offers-item__right-section">
          <OfferDate offer={offer} />
          <div className="my-offers-item__actions">
            <PrimaryCTAButtons offer={offer} />
            <IconButton
              variant="transparent-border-beige"
              shape="squared"
              size="medium"
              aria-label="Application reactions"
              sx={{
                gridColumn: "span 2",
              }}
              onClick={() =>
                user &&
                openMessengerModal({
                  context: {
                    participants: [user?.id, offer.job.creator.id],
                  },
                })
              }
            >
              <MessageIcon style={{ fontSize: "16px" }} />
            </IconButton>
          </div>
        </div>
      }
    />
  );
};

const PrimaryCTAButtons = ({ offer }) => {
  const isNewOrUpdated =
    ENUMS.OfferStatus.NEW === offer.status ||
    ENUMS.OfferStatus.UPDATED === offer.status;

  return offer.status === ENUMS.OfferStatus.ACCEPTED ? (
    <Button
      className="mr-"
      href={`/jobs/${offer.job.id}/offers/${offer.id}/`}
      variant="primary"
      shape="squared"
      RouterLink={RouterLink}
    >
      View offer
    </Button>
  ) : (
    <Button
      className="mr-"
      href={`/jobs/${offer.job.id}/offers/${offer.id}`}
      variant={isNewOrUpdated ? "positive" : "primary"}
      shape="squared"
      RouterLink={RouterLink}
    >
      {isNewOrUpdated ? "Respond to offer" : "View offer"}
    </Button>
  );
};
