import { useEffect, useMemo, useState } from "react";
import { reset } from "redux-form";

import { Box, Divider } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  CreatePostOpenedFieldsContext,
  ResourcesContextProvider,
} from "@js/apps/give-and-get-help/context";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import {
  CREATE_POST_FORM_ID,
  EDIT_POST_FORM_ID,
} from "@js/apps/give-and-get-help/form/constants";
import type { UseCreatePostOpenedFieldsArg } from "@js/apps/give-and-get-help/hooks/post-opened-fields";
import { useCreatePostOpenedFields } from "@js/apps/give-and-get-help/hooks/post-opened-fields";
import { useAppDispatch } from "@js/hooks";
import { assertUnreachable } from "@js/utils";

import { useCheckForSelfPromotion } from "../../hooks/use-check-for-self-promotion";
import { EtiquetteReminder } from "../etiquette-reminder";
import { useShowEtiquetteReminder } from "../etiquette-reminder/use-show-etiquette-reminder";
import { EtiquetteSidePanel } from "../etiquette-side-panel";
import { useEtiquette } from "../etiquette-side-panel/hook";
import { LearnEtiquetteLink } from "../learn-etiquette-link";

import { closeCreatePostModal } from "./modal/close-modal";
import { EditPostModalInstance } from "./modal/modal-instances";
import {
  CreateOrEditPostStepOneContent,
  CreateOrEditPostStepTwoContent,
} from "./content";
import {
  CreateOrEditPostStepOneFooter,
  CreateOrEditPostStepTwoFooter,
} from "./footer";
import { CreateOrEditPostModalHeader } from "./header";

type CreatePostModalStepType = 1 | 2 | 3;

type CreateOrEditPostModalContentProps = {
  linksEditOnClickActive?: boolean;
  editedPostId?: number;
  disablePoll?: boolean;
  initialOpenedFields?: UseCreatePostOpenedFieldsArg["initialOpenedFields"];
  hideEtiquettePanel?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateOrEditPostModalContent = ({
  editedPostId,
  linksEditOnClickActive,
  disablePoll,
  initialOpenedFields,
}: CreateOrEditPostModalContentProps) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [createPostStep, setCreatePostStep] =
    useState<CreatePostModalStepType>(1);

  const dispatch = useAppDispatch();
  const isEditingAPost = Boolean(editedPostId);
  const formId = isEditingAPost ? EDIT_POST_FORM_ID : CREATE_POST_FORM_ID;
  const location = usePostLocationContext();
  const { selfPromotionServerWarmUp } = useCheckForSelfPromotion(formId);

  const { showEtiquetteReminder } = useShowEtiquetteReminder(formId);
  const { closeEtiquettePanel, showEtiquettePanel } = useEtiquette();

  const onNextClick = () => {
    if (createPostStep === 1) {
      setCreatePostStep(showEtiquetteReminder ? 2 : 3);
    }

    if (createPostStep === 2) {
      setCreatePostStep(3);
    }
  };
  const onPreviousClick = () => {
    setCreatePostStep(
      createPostStep === 2 || createPostStep === 3 ? 1 : createPostStep,
    );
  };

  useEffect(() => {
    selfPromotionServerWarmUp();

    return () => {
      dispatch(reset(formId));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let Content: JSX.Element;

  const { openedFields, toggleField, openField } = useCreatePostOpenedFields({
    initialOpenedFields,
    isEditingAPost,
  });

  const handleDragEnter = () => {
    if (!openedFields.includes("attachment_image"))
      openField("attachment_file");
  };

  const createPostOpenFieldsContextProviderValues = useMemo(
    () => ({ openedFields, toggleField, openField }),
    [openedFields, toggleField, openField],
  );

  const handleCloseCreatePostModal = () => {
    dispatch(closeCreatePostModal({ composer_location: location }));
  };

  switch (createPostStep) {
    case 1:
      Content = (
        <ResourcesContextProvider>
          <CreateOrEditPostModalContentWrapper
            onDragEnter={handleDragEnter}
            subheader={<LearnEtiquetteLink />}
            content={
              <CreateOrEditPostStepOneContent
                errors={errors}
                disablePoll={disablePoll}
                isEditingPost={isEditingAPost}
                linksEditOnClickActive={linksEditOnClickActive}
                openField={openField}
              />
            }
            sidebar={
              showEtiquettePanel ? (
                <EtiquetteSidePanel close={closeEtiquettePanel} />
              ) : undefined
            }
            footer={
              <CreateOrEditPostStepOneFooter
                mode={isEditingAPost ? "edit-post" : "create-post"}
                onFirstStepFail={(e) => setErrors(e)}
                formId={formId}
                onNextClick={onNextClick}
                isLinkFieldOpen={openedFields.includes("link")}
                disablePoll={disablePoll}
              />
            }
            closeModal={
              isEditingAPost
                ? EditPostModalInstance.close
                : handleCloseCreatePostModal
            }
          />
        </ResourcesContextProvider>
      );
      break;

    case 2:
      Content = (
        <EtiquetteReminder onContinue={onNextClick} onBack={onPreviousClick} />
      );
      break;

    case 3:
      Content = (
        <CreateOrEditPostModalContentWrapper
          content={
            <CreateOrEditPostStepTwoContent editedPostId={editedPostId} />
          }
          footer={
            <CreateOrEditPostStepTwoFooter
              onPreviousClick={onPreviousClick}
              isEditMode={isEditingAPost}
              formId={formId}
            />
          }
          closeModal={
            isEditingAPost
              ? EditPostModalInstance.close
              : handleCloseCreatePostModal
          }
        />
      );
      break;

    default:
      assertUnreachable(createPostStep);
      return null;
  }

  return (
    <CreatePostOpenedFieldsContext.Provider
      value={createPostOpenFieldsContextProviderValues}
    >
      {Content}
    </CreatePostOpenedFieldsContext.Provider>
  );
};

type CreateOrEditPostModalContentWrapperProps = {
  subheader?: JSX.Element;
  content: JSX.Element;
  sidebar?: JSX.Element;
  footer: JSX.Element;
  closeModal: () => void;
  onDragEnter?: () => void;
};

const CreateOrEditPostModalContentWrapper = ({
  subheader,
  content,
  sidebar,
  footer,
  closeModal,
  onDragEnter,
}: CreateOrEditPostModalContentWrapperProps) => {
  const isMobile = useMediaQuery("sm");

  if (isMobile && !!sidebar) {
    return <Box className="create-or-edit-post-content-wrapper">{sidebar}</Box>;
  }

  return (
    <Box className="create-or-edit-post-content-wrapper">
      {sidebar}
      <Box className="content-wrapper" onDragEnter={onDragEnter}>
        <CreateOrEditPostModalHeader closeModal={closeModal} />

        {subheader}

        <Box mt={3} flexGrow={1}>
          {content}
        </Box>

        <Divider color="secondary" sx={{ my: isMobile ? 2 : 3 }} />

        <Box>{footer}</Box>
      </Box>
    </Box>
  );
};
