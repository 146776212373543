import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PoweredByCoinbase from "@static/on-ramp/poweredByCoinbase.svg";

import {
  Box,
  Button,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { SwapVerticalIcon } from "@hexocean/braintrust-ui-components/Icons";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { OfferVisual } from "@js/apps/common/components/offer-visual";
import { useUser } from "@js/apps/common/hooks/use-user";
import {
  useGetApplicationBoostsMutation,
  useUserReserveBoostsMutation,
} from "@js/apps/jobs/api";
import { useCoinbasePay } from "@js/apps/on-ramp/hooks/useCoinbase";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";

import { openBoostSuccessModal } from "./boost-success-modal/boost-success-modal";

import styles from "./styles.module.scss";

export const ApplicationBoostModalRight = ({
  btrst,
  walletAddress,
  transactionUuid,
  requireBTRST,
  btrstNumber,
}) => {
  const tokensToBuy = requireBTRST;
  const [getApplicationBoosts, { isLoading }] =
    useGetApplicationBoostsMutation();
  const [getUserReserveBoosts] = useUserReserveBoostsMutation();

  const isMobile = useMediaQuery("sm");
  const user = useUser();
  const { onRampURL } = useCoinbasePay({
    transactionUuid,
    walletAddress,
    tokensToBuy,
  });
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const ClickOnCoinbase = async () => {
    if (onRampURL) {
      await getUserReserveBoosts({});
      ModalInstance.close();
      window.open(onRampURL, "_blank", "noreferrer");
    } else {
      Snackbar.error("onRamp URl is null");
    }
  };

  const clickOnPurchaseBoost = async () => {
    if (user?.boost_credit !== undefined && user?.boost_credit > 0) {
      Snackbar.info(
        `Can't purchase boosts, You have ${user?.boost_credit} boost credits remaining.`,
      );
    } else {
      const boost: any = await getApplicationBoosts({});

      if ("error" in boost) {
        return Snackbar.error(
          boost?.error?.data?.error_message || "Error something happend!",
        );
      }
      dispatch(fetchCurrentUser());
      ModalInstance.close();
      openBoostSuccessModal(navigate);
    }
  };

  const topImageSection = () => {
    if (btrst) {
      return (
        <Box>
          <img
            className={styles.leftMainContainer}
            src={`${SETTINGS.STATIC_URL}boostmodal/boost-modal-purchase.png`}
            alt="banner"
          />
        </Box>
      );
    } else {
      return (
        <OfferVisual
          wrapperClassName={styles["onramp-modal__offer-visual"]}
          exchangeFrom={{
            currency: "USD",
            avatar_thumbnail: `${SETTINGS.STATIC_URL}flags/us-round.svg`,
            has_avatar_set: false,
            gravatar: "",
          }}
          exchangeTo={{
            currency: "BTRST",
            avatar_thumbnail: "",
            has_avatar_set: false,
            gravatar: "",
          }}
          icon={<SwapVerticalIcon />}
          isInverse
          largePill={!isMobile}
        />
      );
    }
  };

  const totalEstimateSection = () => {
    if (btrst) {
      return (
        <>
          <Box display={"flex"} justifyContent={"space-between"} gap={1}>
            <Typography component="p" size="medium">
              3 Application Boosts
            </Typography>
            <Typography component="p" size="medium">
              39 BTRST
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} gap={1}>
            <Typography component="p" size="medium" fontWeight={500}>
              Total
            </Typography>
            <Typography component="p" size="medium" fontWeight={500}>
              39 BTRST
            </Typography>
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Box display={"flex"} justifyContent={"space-between"} gap={1}>
            <Typography component="p" size="medium">
              3 Application Boosts
            </Typography>
            <Typography component="p" size="medium">
              {btrstNumber} BTRST
            </Typography>
          </Box>

          <Box display={"flex"} justifyContent={"space-between"} gap={1}>
            <Typography component="p" size="medium" fontWeight={500}>
              Total you need to buy
            </Typography>
            <Typography component="p" size="medium" fontWeight={500}>
              <Tooltip title={requireBTRST} open={isOpen}>
                <span
                  onMouseEnter={() => setIsOpen(true)}
                  onMouseLeave={() => setIsOpen(false)}
                >
                  {parseFloat(requireBTRST).toFixed(2)}
                </span>
              </Tooltip>{" "}
              BTRST
            </Typography>
          </Box>
        </>
      );
    }
  };

  return (
    <Box display={"flex"} justifyContent={"center"} py={5}>
      <Box maxWidth={"393px"} p={2}>
        {topImageSection()}

        <Box>
          <Typography
            component="h2"
            variant="title"
            size="small"
            fontWeight={400}
            mt={3}
            mb={2}
          >
            {btrst
              ? "Purchase Application Boosts"
              : "Purchase BTRST for Application Boosts"}
          </Typography>
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <hr />

          <Box display={"flex"} flexDirection={"column"} gap={1}>
            {totalEstimateSection()}
          </Box>

          <hr />

          {btrst ? (
            <Typography component="p" size="small">
              By confirming payment you accept our{" "}
              <span className="link_design">
                {" "}
                <a
                  href="https://www.usebraintrust.com/privacy-policy "
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Privacy Policy
                </a>
              </span>{" "}
              and{" "}
              <span>
                <a
                  href="https://www.usebraintrust.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Payments Terms & Conditions
                </a>
              </span>
            </Typography>
          ) : (
            <>
              <Box bgcolor={"#F5F8FF"} p={2} borderRadius={3}>
                <Typography component="p" size="small">
                  We'll notify you when your BTRST {btrst ? "are" : "is"}{" "}
                  available. This could take up to 5 minutes.
                </Typography>
              </Box>

              <Typography component="p" size="small">
                By confirming payment you accept our{" "}
                <span className="link_design">
                  {" "}
                  <a
                    href="https://www.usebraintrust.com/privacy-policy "
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Privacy Policy
                  </a>
                </span>{" "}
                and{" "}
                <span>
                  <a
                    href="https://www.usebraintrust.com/terms"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Payments Terms & Conditions
                  </a>
                </span>
              </Typography>
            </>
          )}

          <Box mt={1}>
            <Button
              disabled={isLoading}
              onClick={btrst ? clickOnPurchaseBoost : ClickOnCoinbase}
              variant="positive"
              sx={{
                height: "56px",
                borderRadius: "10px !important",
                width: "100%",
              }}
              {...(!btrst
                ? {
                    endIcon: (
                      <img
                        src={`${SETTINGS.STATIC_URL}boostmodal/back-right.svg`}
                        alt="diagonalright"
                      />
                    ),
                  }
                : {})}
            >
              {btrst
                ? "Purchase Application Boosts"
                : "Get started with Coinbase"}
            </Button>
          </Box>
          {!btrst ? (
            <Box mt={2}>
              <PoweredByCoinbase />
            </Box>
          ) : null}
        </Box>
      </Box>{" "}
    </Box>
  );
};
