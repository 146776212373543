import {
  BriefcaseIcon,
  EarnIcon,
  JobsIcon,
  WelcomeIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import type { DashboardNavItem } from "../types";

export const EMPLOYER_NAV_ITEMS = {
  HOME: {
    label: "Home",
    path: "/employer/dashboard/welcome/",
    icon: WelcomeIcon,
  },
  MY_JOBS: {
    path: "/employer/dashboard/my_jobs/",
    label: "My Jobs",
    icon: JobsIcon,
  },
  INVOICES: {
    path: "/employer/invoices/",
    label: "Invoices",
    icon: EarnIcon,
  },
  TALENT: {
    path: "/talent/",
    label: "Talent",
    icon: BriefcaseIcon,
  },
};

type GetEmployerNavItemsProps = {
  canViewInvoices: boolean;
};

export const getEmployerNavItems = ({
  canViewInvoices,
}: GetEmployerNavItemsProps): DashboardNavItem[] =>
  [
    EMPLOYER_NAV_ITEMS.HOME,
    EMPLOYER_NAV_ITEMS.MY_JOBS,
    canViewInvoices && EMPLOYER_NAV_ITEMS.INVOICES,
    EMPLOYER_NAV_ITEMS.TALENT,
  ].filter(Boolean) as [];
