import React from "react";
import cs from "classnames";

import type { CarouselProps } from "@hexocean/braintrust-ui-components";
import {
  Box,
  Carousel,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import type { Freelancer } from "@js/types/freelancer";
import type { JobListingJob } from "@js/types/jobs";

import type { CarouselHeaderProps } from "./header";
import { CarouselHeader } from "./header";

export * from "./header";

const LARGE_SPACING = 24;
const MOBILE_SPACING = 16;

export type CardCarouselSectionProps = {
  title: string;
  items?: Array<Freelancer | JobListingJob>;
  //cardOrder starts from 1
  renderItem: (item: any, cardOrder: number, state?: any) => JSX.Element;
  url?: string;
  urlLabel?: string;
  loading: boolean;
  noItemsPlaceholder?: string;
  transitionProps?: any;
  swiperStyle?: CarouselProps["style"];
  carouselHeaderProps?: Partial<CarouselHeaderProps>;
  extendedWidth?: boolean;
};

export const CardCarouselSection = React.forwardRef<
  any,
  CardCarouselSectionProps
>(
  (
    {
      title,
      items = [],
      renderItem,
      url,
      urlLabel = "View all jobs",
      loading,
      noItemsPlaceholder = "No items yet.",
      transitionProps,
      swiperStyle = { padding: 2 }, // to make focus link visible
      carouselHeaderProps,
      extendedWidth,
    },
    ref,
  ) => {
    const isMobile = useMediaQuery(700);

    const itemsSpacing = isMobile ? MOBILE_SPACING : LARGE_SPACING;

    return (
      <Box width={1} ref={ref} {...transitionProps}>
        <CarouselHeader
          title={title}
          url={url}
          urlLabel={urlLabel}
          mb={2}
          hideNavigationButtons
          {...carouselHeaderProps}
        />
        {loading ? (
          <Loader />
        ) : !items.length ? (
          <Box mt={-2}>
            <Typography component="p">{noItemsPlaceholder}</Typography>
          </Box>
        ) : (
          <Carousel
            slides={items.map((item, index) => {
              return renderItem(item, index + 1);
            })}
            slideProps={{
              style: {
                height: "auto",
                width: "min-content",
                overflow: "hidden",
                borderRadius: "16px",
              },
            }}
            mousewheel={{
              forceToAxis: true,
            }}
            className={cs(extendedWidth && "home-carousel-extended-width")}
            observer
            observeParents
            grabCursor
            threshold={10}
            style={swiperStyle}
            spaceBetween={itemsSpacing}
            slidesPerGroup={1}
            breakpoints={{
              600: {
                slidesPerGroup: 2,
              },
            }}
          />
        )}
      </Box>
    );
  },
);
