import { MyWorkListingPage } from "../my-work-listing-page";

const MY_BIDS_NAVIGATION_ITEMS = [
  {
    label: "Open applications",
    path: "/talent/dashboard/my_jobs/my_proposals/open_applications",
  },
  {
    label: "Closed applications",
    path: "/talent/dashboard/my_jobs/my_proposals/past_applications",
  },
];

export const FreelancerDashboardBidsListingPage = () => {
  return (
    <MyWorkListingPage
      items={MY_BIDS_NAVIGATION_ITEMS}
      pageTitle="Applications"
    />
  );
};
