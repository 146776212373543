import { assertUnreachable } from "@js/utils";

import { StatusLabel } from "../status-label";

interface InternalTransferStatusLabelProps {
  status?: EnumType<typeof ENUMS.InternalTokenTransferStatus>;
  frozen: boolean;
  refund_requested: boolean;
}

export const InternalTransferStatusLabel = ({
  status,
  frozen,
  refund_requested,
}: InternalTransferStatusLabelProps) => {
  if (frozen) {
    return <StatusLabel variant="pending">In escrow</StatusLabel>;
  }
  if (refund_requested) {
    return <StatusLabel variant="pending">Refund requested</StatusLabel>;
  }

  if (!status) return null;

  switch (status) {
    case ENUMS.InternalTokenTransferStatus.SUCCESS: {
      return <StatusLabel variant="success">Completed</StatusLabel>;
    }
    case ENUMS.InternalTokenTransferStatus.FAILED:
    case ENUMS.InternalTokenTransferStatus.REJECTED_DUE_TO_USER_REMOVAL: {
      return <StatusLabel variant="failed">Failed</StatusLabel>;
    }
    case ENUMS.InternalTokenTransferStatus.PENDING:
    case ENUMS.InternalTokenTransferStatus.AWAITING_WALLET_CREATION: {
      return <StatusLabel variant="pending">Pending</StatusLabel>;
    }
    default: {
      assertUnreachable(status);
      return null;
    }
  }
};
